import { ApiError, CreateGantryDeviceDto, GantryDevice, ListGantryDevicesRequest } from '@36node-fcp/core-sdk';
import { Button, Divider, Form, Input, message, Popconfirm } from 'antd';
import { AxiosError } from 'axios';
import { map } from 'lodash';
import { useMemo } from 'react';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { ExportModal } from 'src/components/xlsx-export-modal';
import { ImportModal } from 'src/components/xlsx-import-modal';
import { useGantryList } from 'src/features/gantry';
import {
  DeviceTypeTextList,
  GantryDeviceApiErrorMap,
  GantryDeviceStateBadge,
  GantryDeviceStateUiList,
  ProductUiList,
  ProtocolTypeTextList,
  toDeviceTypeText,
  toDeviceTypeValue,
  toGantryDeviceStateText,
  toProductText,
  toProductValue,
  toProtocolTypeText,
  toProtocolTypeValue,
  useGantryDeviceList,
} from 'src/features/gantry-device';
import { useSearch } from 'src/hook/search';
import { parseIntN } from 'src/lib/lang/number';
import { useApi } from 'src/lib/react-api';
import { useSlice } from 'src/lib/react-slice';
import { ImportValidateError } from 'src/lib/react-xlsx';
import { fcp, passFcpApiErrors } from 'src/services';

import { GantryDeviceEditor } from './gantry-device.editor';

type SearchValues = {} & ListGantryDevicesRequest;
type SearchFormProps = {
  onSearch: (values: SearchValues) => void;
  initialValues?: SearchValues;
};

const defaultQuery: ListGantryDevicesRequest = { _limit: 10, _offset: 0, _sort: '-createAt' };

const SearchForm: React.FC<SearchFormProps> = ({ onSearch, initialValues }) => {
  return (
    <Form onFinish={onSearch} initialValues={initialValues} layout={'inline'}>
      <Form.Item name="name_like">
        <Input placeholder="设备名查询" allowClear />
      </Form.Item>
    </Form>
  );
};

type State = {
  importVisible: boolean;
  exportVisible: boolean;
  editorVisible: boolean;
  editGantryDevice?: GantryDevice;
};

const initState: State = {
  importVisible: false,
  exportVisible: false,
  editorVisible: false,
};

const reducers = {
  openImport(state: State) {
    state.importVisible = true;
  },
  closeImport(state: State) {
    state.importVisible = false;
  },
  openExport(state: State) {
    state.exportVisible = true;
  },
  closeExport(state: State) {
    state.exportVisible = false;
  },
  openEditor(state: State, gantryDevice?: GantryDevice) {
    state.editorVisible = true;
    state.editGantryDevice = gantryDevice;
  },
  closeEditor(state: State) {
    state.editorVisible = false;
    state.editGantryDevice = undefined;
  },
};

const upload = async (doc: CreateGantryDeviceDto) =>
  fcp.upsertGantryDevice({ body: doc }).catch((err: AxiosError<ApiError>) => {
    throw passFcpApiErrors(err.response.data, GantryDeviceApiErrorMap);
  });

const beforeUpload = async (records: CreateGantryDeviceDto[]) => {
  const nameSet = new Set();
  const codeSet = new Set();
  const errors: ImportValidateError[] = [];
  records.forEach(({ name, code }, index) => {
    if (nameSet.has(name)) {
      errors.push({
        row: index + 2,
        message: '文件内设备名称重复',
      });
    }
    nameSet.add(name);
    if (code && codeSet.has(code)) {
      errors.push({
        row: index + 2,
        message: '文件内设备编码重复',
      });
    }
    if (code) codeSet.add(code);
  });
  return errors;
};

const toQuery = (values: SearchValues): ListGantryDevicesRequest => {
  return { ...defaultQuery, ...values };
};

/**
 * 设备管理页面
 */
const GantryDevicePage: React.FC = () => {
  const [search, setSearch] = useSearch<SearchValues>();
  const [{ result: gantries = [] }] = useGantryList({ _limit: 1000 });
  const [{ result, loading, request = {}, total }, listGantryDevices] = useGantryDeviceList(toQuery(search));
  const { _limit: limit = 10, _offset: offset = 0 } = request;
  const [{ importVisible, exportVisible, editorVisible, editGantryDevice }, dispatch] = useSlice(reducers, initState);

  const deleteOptions = useMemo(
    () => ({
      onSuccess: () => {
        message.success('删除成功');
        listGantryDevices(request);
      },
      onFailure: (err) => {
        message.error(`删除失败: ${err.message}`);
      },
    }),
    [request]
  );
  const [deleteState, deleteGantryDevice] = useApi(fcp.deleteGantryDevice, deleteOptions);

  // 搜索、分页、排序触发
  const handleSearch = (values: SearchValues) => {
    setSearch({
      ...search, // 上一次留存的查询条件
      _offset: 0, // 重置页码
      ...values, // 本次输入的查询条件，取消的条件用 undefined 覆盖上一次的查询条件
    });
  };

  const handleRefresh = () => listGantryDevices(request);

  const columns = useMemo<AdColumnsType<GantryDevice>>(
    () => [
      {
        title: '设备名',
        dataIndex: 'name',
        rules: [{ required: true, message: '请填写设备名' }],
      },
      {
        title: '所属卡口',
        key: 'gantry',
        dataIndex: ['gantry', 'name'],
        rules: [{ required: true, message: '请填写正确卡口', type: 'enum', enum: map(gantries, 'name') }],
        import: (name: string) => gantries.find((item) => item.name === name)?.id,
      },
      {
        title: '状态',
        dataIndex: 'state',
        render: (val) => <GantryDeviceStateBadge state={val} />,
        filters: GantryDeviceStateUiList,
        filterMultiple: false,
        compute: toGantryDeviceStateText,
        importDisabled: true,
        defaultFilteredValue: search.state && [].concat(search.state),
      },
      {
        title: '车道编号',
        dataIndex: 'carWayCode',
        defaultHide: true,
        import: String,
      },
      {
        title: '协议类型',
        dataIndex: 'protocolType',
        defaultHide: true,
        rules: [{ type: 'enum', enum: ProtocolTypeTextList, message: '请填写正确的协议类型' }],
        compute: toProtocolTypeText,
        import: toProtocolTypeValue,
      },
      {
        title: '厂商',
        dataIndex: 'product',
        defaultHide: true,
        rules: [{ type: 'enum', enum: map(ProductUiList, 'text'), message: '请填写正确的厂商' }],
        compute: toProductText,
        import: toProductValue,
      },
      {
        title: '设备大类',
        dataIndex: 'type',
        defaultHide: true,
        rules: [{ required: true, type: 'enum', enum: DeviceTypeTextList, message: '请填写正确的设备大类' }],
        compute: toDeviceTypeText,
        import: toDeviceTypeValue,
      },
      {
        title: 'IP地址',
        dataIndex: 'ip',
        defaultHide: true,
        rules: [
          {
            pattern:
              /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            message: 'IP地址无效',
          },
        ],
      },
      {
        title: '设备端口',
        dataIndex: 'port',
        defaultHide: true,
        rules: [{ type: 'integer', min: 0, max: 65535, message: '设备端口无效', transform: parseIntN }],
        import: parseIntN,
      },
      {
        title: '用户',
        dataIndex: 'username',
        defaultHide: true,
        import: String,
      },
      {
        title: '密码',
        dataIndex: 'password',
        defaultHide: true,
        import: String,
      },
      {
        title: '限速',
        dataIndex: 'speedLimit',
        defaultHide: true,
        rules: [{ type: 'integer', min: 1, max: 120, message: '限速值无效', transform: parseIntN }],
        import: parseIntN,
      },
      {
        title: '设备编码',
        dataIndex: 'code',
        defaultHide: true,
        import: String,
      },
      {
        title: '机构代码',
        dataIndex: 'collectionAgency',
        defaultHide: true,
        import: String,
      },
      {
        title: '操作',
        render: (node, record) => (
          <>
            <Button type="link" style={{ padding: 0 }} onClick={() => dispatch.openEditor(record)}>
              编辑
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="你确定要删除这行内容吗？"
              onConfirm={() => deleteGantryDevice({ gantryDeviceId: record.id })}
            >
              <Button type="link" danger style={{ padding: 0 }} loading={deleteState.loading}>
                删除
              </Button>
            </Popconfirm>
          </>
        ),
      },
    ],
    [dispatch, gantries, deleteGantryDevice, search]
  );

  return (
    <>
      <AdTable
        columns={columns}
        title="设备管理"
        rowKey="id"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={result}
        onAddNew={() => dispatch.openEditor()}
        onChange={handleSearch}
        onRefresh={handleRefresh}
        onExport={dispatch.openExport}
        onUpload={dispatch.openImport}
        pagination={{
          total,
          current: offset / limit + 1,
          pageSize: limit,
        }}
        extraTools={<SearchForm onSearch={handleSearch} initialValues={search} />}
      />
      {exportVisible && (
        <ExportModal
          api={fcp.listGantryDevices}
          args={request}
          columns={columns}
          filename="设备.xlsx"
          onClose={dispatch.closeExport}
          title="设备导出"
          total={total}
        />
      )}
      {importVisible && (
        <ImportModal
          api={upload}
          isSupportConcurrency
          concurrentNumber={5}
          beforeUpload={beforeUpload}
          columns={columns}
          template="设备管理导入模板.xlsx"
          onClose={dispatch.closeImport}
          onFinish={handleRefresh}
          title="设备导入"
        />
      )}
      {editorVisible && (
        <GantryDeviceEditor onClose={dispatch.closeEditor} onFinish={handleRefresh} gantryDevice={editGantryDevice} />
      )}
    </>
  );
};

export default GantryDevicePage;
