import { ListUsersRequest, Namespace, User } from '@36node-fcp/auth-sdk';
import { DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
  Spin,
  Switch,
  Tree,
  Typography,
} from 'antd';
import { isArray, isEmpty } from 'lodash';
import { useMemo } from 'react';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { ROOT_NS } from 'src/config';
import { listToTree, useNamespaceList, useUserList } from 'src/features/users';
import { useSearch } from 'src/hook/search';
import { Omits } from 'src/lib/lang/tsHelpers';
import { useApi } from 'src/lib/react-api';
import { useSlice } from 'src/lib/react-slice';
import { auth } from 'src/services';

import { NamespacesEditor } from './namespace.editor';
import { UsersEditor } from './users.editor';

type SearchValues = { showSub?: boolean } & ListUsersRequest;
type SearchFormProps = {
  initialValues?: SearchValues;
  onSearch: (values: SearchValues) => void;
  onSwitchShowSub: (checked: boolean) => void;
};
export type EditNamespace = {
  ns: Omits<Namespace, ['isScope', 'ns']>;
  isEdit: boolean;
  isScope?: boolean;
};

const defaultQuery: SearchValues = {
  _limit: 10,
  _offset: 0,
  _sort: ListUsersRequest.Sort.CREATE_AT_DESC,
  showSub: true,
  ns_scope: [ROOT_NS],
  ns: [ROOT_NS],
};
const SearchForm: React.FC<SearchFormProps> = ({ onSearch, onSwitchShowSub: onSwitchNamesapce, initialValues }) => {
  return (
    <Flex gap="large">
      <Form onFinish={onSearch} initialValues={initialValues} layout={'inline'}>
        <Form.Item name="showSub" label="显示子部门成员" valuePropName="checked">
          <Switch onChange={onSwitchNamesapce} />
        </Form.Item>
        <Form.Item name="nickname_like">
          <Input placeholder="用户姓名查询" allowClear />
        </Form.Item>
      </Form>
    </Flex>
  );
};

type State = {
  editorVisible: boolean;
  editUser?: User;
  editNamespace?: EditNamespace;
  namespaceEditorVisible: boolean;
  namespaceDeleteVisible: boolean;
};

const initState: State = {
  editorVisible: false,
  namespaceEditorVisible: false,
  namespaceDeleteVisible: false,
};

const reducers = {
  openEditor(state: State, user?: User) {
    state.editorVisible = true;
    state.editUser = user;
  },
  closeEditor(state: State) {
    state.editorVisible = false;
    state.editUser = undefined;
  },
  openNamespaceEditor(state: State, Namespace?: EditNamespace) {
    state.namespaceEditorVisible = true;
    state.editNamespace = Namespace;
  },
  closeNamespaceEditor(state: State) {
    state.namespaceEditorVisible = false;
    state.editNamespace = undefined;
  },
  openNamespaceDelete(state: State) {
    state.namespaceDeleteVisible = true;
  },
  closeNamespaceDelete(state: State) {
    state.namespaceDeleteVisible = false;
  },
};

const toQuery = (search: SearchValues): ListUsersRequest => {
  const values = Object.keys(search).length === 0 ? defaultQuery : search;
  const { ns, ns_scope, showSub, ...rest } = values;
  const nsFilter = showSub ? { ns_scope: ns, ns: undefined } : { ns, ns_scope: undefined };
  return { ...defaultQuery, ...rest, ...nsFilter };
};

/**
 * 用户管理页面
 */
const UsersPage: React.FC = () => {
  const [search, setSearch] = useSearch<SearchValues>({ showSub: true });
  const selectedNamespaceFull = (isArray(search.ns) ? search.ns[0] : search.ns) || ROOT_NS;
  const [{ editorVisible, editNamespace, editUser, namespaceEditorVisible, namespaceDeleteVisible }, dispatch] =
    useSlice(reducers, initState);

  const [{ result: namespaces = [], loading: listNamespacesLoading }, listNamespaces] = useNamespaceList({
    _limit: 1000,
    ns_start: ROOT_NS,
  });
  const [{ result, loading, request = {}, total }, listUsers] = useUserList(toQuery(search));
  const { _limit: limit = 10, _offset: offset = 0 } = request;

  const deleteUserOptions = useMemo(
    () => ({
      onSuccess: () => {
        message.success('删除成功');
        handleRefresh();
      },
      onFailure: (err) => {
        message.error(`删除失败: ${err.message}`);
      },
    }),
    [request]
  );
  const [deleteState, deleteUser] = useApi(auth.deleteUser, deleteUserOptions);

  const deleteNamespaceOptions = useMemo(
    () => ({
      onSuccess: () => {
        message.success('删除成功');
        handleRefreshNamespaces();
        setSearch({ ...search, ns_scope: [ROOT_NS], _offset: 0 });
      },
      onFailure: (err) => {
        message.error(`删除失败: ${err.message}`);
      },
    }),
    []
  );
  const [namespaceDeleteState, deleteNamespace] = useApi(auth.deleteNamespace, deleteNamespaceOptions);

  // 搜索、分页、排序触发
  const handleSearch = (values: SearchValues) => {
    setSearch({
      ...search, // 上一次留存的查询条件
      _offset: 0, // 重置页码
      ...values, // 本次输入的查询条件，取消的条件用 undefined 覆盖上一次的查询条件
    });
  };

  const handleSwitchShowSub = (showSub: boolean) => {
    setSearch({ ...(Object.keys(search).length === 0 ? defaultQuery : search), showSub, _offset: 0 });
  };

  const handleRefresh = () => listUsers(request);

  const handleRefetch = (keys: string[]) => {
    if (isEmpty(keys)) {
      return;
    }

    const key = keys[0];
    if (key === selectedNamespaceFull) {
      return;
    }

    setSearch({ ...(Object.keys(search).length === 0 ? defaultQuery : search), ns: [key], _offset: 0 });
  };

  const handleRefreshNamespaces = () => listNamespaces({ _limit: 1000, ns_start: ROOT_NS });

  const handlenamespaceDeleteCheck = (newOpen: boolean) => {
    if (!newOpen) {
      dispatch.closeNamespaceDelete();
      return;
    }

    // 确保该分组下没有用户
    if (total > 0) {
      message.warning('请将该分组下用户移出后再次尝试删除');
      return;
    }

    // 确保该分组下没有子分组
    const subNamespaces = namespaces.filter((item) => item.parent === selectedNamespaceFull);
    if (subNamespaces.length > 0) {
      message.warning('请将该分组下子分组移出后再次尝试删除');
      return;
    }

    dispatch.openNamespaceDelete();
  };

  const selectedNamespace: Namespace = useMemo(
    () => namespaces.find((item) => item.ns === selectedNamespaceFull),
    [namespaces, selectedNamespaceFull]
  );

  const updateNamespaceOptions = useMemo(
    () => ({
      onSuccess: () => {
        message.success('编辑成功');
        handleRefreshNamespaces();
      },
      onFailure: (err) => {
        message.error(`编辑失败: ${err.message}`);
      },
    }),
    []
  );

  const [, updateNamespace] = useApi(auth.updateNamespace, updateNamespaceOptions);

  let _editNamespaceName = '';
  const handleUpdateNamespaceName = () => {
    updateNamespace({
      namespaceId: selectedNamespace?.id,
      body: { name: _editNamespaceName },
    });
  };

  const columns: AdColumnsType<User> = useMemo(
    () => [
      {
        title: '用户名',
        dataIndex: 'username',
        rules: [{ required: true, message: '请填写用户名' }],
      },
      {
        title: '姓名',
        dataIndex: 'nickname',
        rules: [{ required: true, message: '请填写姓名' }],
      },
      {
        title: '所属部门',
        dataIndex: 'ns',
        compute: (val) => {
          const namespace = namespaces.find((item) => item.ns === val);
          return namespace ? namespace.name : '--';
        },
        rules: [{ required: true, message: '请选择所属部门' }],
      },
      {
        title: '操作',
        render: (node, record) => (
          <>
            <Button type="link" style={{ padding: 0 }} onClick={() => dispatch.openEditor(record)}>
              编辑
            </Button>
            <Divider type="vertical" />
            <Popconfirm title="你确定要删除这个用户吗？" onConfirm={() => deleteUser({ userId: record.id })}>
              <Button type="link" danger style={{ padding: 0 }} loading={deleteState.loading}>
                删除
              </Button>
            </Popconfirm>
          </>
        ),
      },
    ],
    [dispatch, namespaces, deleteUser]
  );

  return (
    <Row gutter={16} wrap={false}>
      <Col flex="250px" style={{ backgroundColor: '#FAFAFA', padding: '15px 5px', overflow: 'scroll' }}>
        <Spin spinning={listNamespacesLoading}>
          {!listNamespacesLoading && (
            <Tree
              style={{ backgroundColor: '#FAFAFA' }}
              treeData={listToTree(namespaces)}
              selectedKeys={[selectedNamespaceFull]}
              onSelect={handleRefetch}
              defaultExpandAll
              blockNode
              titleRender={(nodeData) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography.Text
                    ellipsis={{ tooltip: `${nodeData.title}` }}
                    style={{ marginRight: 12, maxWidth: '150px' }}
                  >
                    {nodeData.title}
                  </Typography.Text>

                  {selectedNamespace?.ns === nodeData.key && (
                    <Space>
                      <PlusSquareOutlined
                        className="tree-add"
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch.openNamespaceEditor({
                            isEdit: false,
                            ns: {
                              id: '',
                              parent: isArray(search?.ns_scope) ? search.ns_scope[0] : search.ns_scope,
                              name: '',
                              key: '',
                            },
                          });
                        }}
                      />

                      {selectedNamespace?.ns !== ROOT_NS && (
                        <Popconfirm
                          title="确认删除该分组吗？"
                          open={namespaceDeleteVisible}
                          onOpenChange={handlenamespaceDeleteCheck}
                          okButtonProps={{ loading: namespaceDeleteState.loading }}
                          onConfirm={() => deleteNamespace({ namespaceId: selectedNamespace?.id })}
                          onCancel={() => dispatch.closeNamespaceDelete()}
                        >
                          <DeleteOutlined />
                        </Popconfirm>
                      )}
                    </Space>
                  )}
                </div>
              )}
            />
          )}
        </Spin>
      </Col>
      <Col flex="auto">
        <AdTable
          style={{ flex: 1 }}
          columns={columns}
          title={
            <Typography.Paragraph
              style={{ padding: '15px 0 0 15px' }}
              editable={{
                onEnd: () => handleUpdateNamespaceName(),
                onChange: (value) => (_editNamespaceName = value),
              }}
            >
              {selectedNamespace?.name || '用户管理'}
            </Typography.Paragraph>
          }
          rowKey="id"
          loading={loading}
          scroll={{ x: 'max-content' }}
          dataSource={result}
          onAddNew={() => dispatch.openEditor()}
          onChange={handleSearch}
          onRefresh={handleRefresh}
          pagination={{
            total,
            current: offset / limit + 1,
            pageSize: limit,
          }}
          extraTools={
            <SearchForm initialValues={search} onSearch={handleSearch} onSwitchShowSub={handleSwitchShowSub} />
          }
        />
      </Col>
      {editorVisible && (
        <UsersEditor
          onClose={dispatch.closeEditor}
          onFinish={handleRefresh}
          user={editUser}
          defaultNamespace={selectedNamespace?.ns}
        />
      )}
      {namespaceEditorVisible && (
        <NamespacesEditor
          namespace={editNamespace}
          onClose={dispatch.closeNamespaceEditor}
          onFinish={handleRefreshNamespaces}
          defaultParent={selectedNamespace.ns}
        />
      )}
    </Row>
  );
};

export default UsersPage;
