import { ApiError, CreateGantryDto, Gantry } from '@36node-fcp/core-sdk';
import { Form, Input, InputNumber, message, Modal } from 'antd';
import styled from 'styled-components';

import { GantryApiErrorMap, GantryAttrSelect } from 'src/features/gantry';
import { LawEnforcePointSelect } from 'src/features/law-enforce-point';
import { RoadSectionSelect } from 'src/features/road-section';
import { NamespaceCascaderSelect } from 'src/features/users';
import { useApi } from 'src/lib/react-api';
import { fcp, passFcpApiErrors } from 'src/services';

type EditorValues = CreateGantryDto & {};

interface GantryEditorProps {
  gantry?: Gantry;
  onClose: () => void;
  onFinish?: () => void;
}

export const GantryEditor: React.FC<GantryEditorProps> = ({ gantry, onClose: handleClose, onFinish: handleFinish }) => {
  const isEdit = !!gantry;
  const [form] = Form.useForm<EditorValues>();

  const onSuccess = () => {
    message.success(isEdit ? '编辑成功' : '创建成功');
    handleClose();
    handleFinish?.();
  };

  const onFailure = (err: ApiError) => {
    const ne = passFcpApiErrors(err, GantryApiErrorMap);
    if (ne.keys) {
      ne.keys.forEach((key) => form.setFields([{ name: key, errors: [ne.message[key]] }]));
    } else {
      message.error(ne.message as string);
    }
  };
  const [updateState, update] = useApi(fcp.updateGantry, { onSuccess, onFailure });
  const [createState, create] = useApi(fcp.createGantry, { onSuccess, onFailure });

  const handleOk = () => form.submit();
  const handleSubmit = async (values: EditorValues) => {
    // 这里 section 和 lawwEnforcePoint 的路径参看对哦应 FormItem 的 name
    // 因此这里需要重新编辑一下路径
    // 原因是 create 的参数和 API 返回的参数在此处略有不同
    if (!values.section) values.section = null;
    if (!values.lawEnforcePoint) values.lawEnforcePoint = null;

    if (isEdit) {
      update({ gantryId: gantry.id, body: values });
    } else {
      create({ body: values });
    }
  };

  const isLoading = updateState.loading || createState.loading;
  const title = isEdit ? '编辑卡口' : '新建卡口';
  const initialValues = isEdit
    ? { ...gantry, section: gantry.section?.id, lawEnforcePoint: gantry.lawEnforcePoint?.id }
    : {};

  return (
    <Modal title={title} open onOk={handleOk} onCancel={handleClose} confirmLoading={isLoading}>
      <Container>
        <Form
          name="illegal-type-editor"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={initialValues}
          autoComplete="off"
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item label="卡口名" name="name" rules={[{ required: true, message: '请填写卡口名' }]}>
            <Input placeholder="填写卡口名" />
          </Form.Item>
          <Form.Item label="所属部门" name="ns" rules={[{ required: true, message: '请选择所属部门' }]}>
            <NamespaceCascaderSelect />
          </Form.Item>
          <Form.Item label="所属路段" name="section">
            <RoadSectionSelect allowClear />
          </Form.Item>
          <Form.Item label="卡口属性" name="attr" rules={[{ required: true, message: '请选择卡口属性' }]}>
            <GantryAttrSelect allowClear />
          </Form.Item>
          <Form.Item label="所属执法点" name="lawEnforcePoint">
            <LawEnforcePointSelect allowClear />
          </Form.Item>
          <Form.Item label="经度" name="lng" rules={[{ required: true, message: '请填写经度' }]}>
            <InputNumber placeholder="填写经度" controls={false} style={{ width: 200 }} />
          </Form.Item>
          <Form.Item label="纬度" name="lat" rules={[{ required: true, message: '请填写纬度' }]}>
            <InputNumber placeholder="填写纬度" controls={false} style={{ width: 200 }} />
          </Form.Item>
        </Form>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  margin: 24px;
`;
