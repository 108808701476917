import { CreateNamespaceRequest } from '@36node-fcp/auth-sdk';
import { Alert, Form, Input, message, Modal } from 'antd';
import styled from 'styled-components';

import { NamespaceApiErrorMap, NamespaceCascaderSelect } from 'src/features/users';
import { useApi } from 'src/lib/react-api';
import { ApiErrorCode, auth, passFcpApiErrors } from 'src/services';

import { EditNamespace } from './users.page';

type EditorValues = CreateNamespaceRequest & {};

interface NamespaceEditorProps {
  namespace?: EditNamespace;
  onClose: () => void;
  onFinish?: () => void;
  defaultParent?: string;
}

export const NamespacesEditor: React.FC<NamespaceEditorProps> = ({
  namespace,
  onClose: handleClose,
  onFinish: handleFinish,
  defaultParent,
}) => {
  const { ns, isEdit } = namespace;
  console.log('namespace', namespace);
  console.log('defaultParent', defaultParent);
  const onSuccess = () => {
    message.success(isEdit ? '编辑成功' : '创建成功');
    handleClose();
    handleFinish?.();
  };
  const onFailure = (err: any) => {
    const ne = passFcpApiErrors(err, NamespaceApiErrorMap);
    if (ne.keys) {
      const errors = ne.keys.map((key) => ({ name: key, errors: [ne.message[key]] }));
      form.setFields(errors);
    } else {
      message.error(ne.message as string);
    }
  };

  const [form] = Form.useForm<EditorValues>();
  const [updateState, update] = useApi(auth.updateNamespace, { onSuccess, onFailure });
  const [createState, create] = useApi(auth.createNamespace, { onSuccess, onFailure });

  const handleOk = () => form.submit();
  const handleSubmit = async (values: any) => {
    if (isEdit) {
      update({ namespaceId: ns.id, body: values });
    } else {
      create({
        body: {
          parent: values.parent,
          key: values.key,
          name: values.name,
          ns: `${values.parent}.${values.key}`,
        },
      });
    }
  };

  const isLoading = updateState.loading || createState.loading;
  const title = isEdit ? '编辑分组' : '新增分组';
  const error = updateState.error || createState.error;

  return (
    <Modal title={title} open onOk={handleOk} onCancel={handleClose} confirmLoading={isLoading} width="650px">
      <Container>
        {error && !Object.values(ApiErrorCode).includes(error.code) && <Alert message={error.message} type="error" />}
        <Form
          name="namespace-editor"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          autoComplete="off"
          onFinish={handleSubmit}
          form={form}
          initialValues={isEdit ? ns : { parent: defaultParent, key: '', name: '' }}
        >
          <Form.Item
            label="分组ID"
            name="key"
            rules={[
              { required: true, message: '请填写分组ID' },
              {
                pattern: /^[A-Za-z][A-Za-z\d_]+$/,
                message: '必须英文字母开头，可以包含数字和下划线',
              },
            ]}
            initialValue={isEdit ? ns.key : undefined}
          >
            <Input placeholder="请输入英文" autoComplete="off" />
          </Form.Item>
          <Form.Item label="分组名" name="name" rules={[{ required: true, message: '请填分组名' }]}>
            <Input placeholder="填写分组名" autoComplete="off" />
          </Form.Item>
          <Form.Item label="父分组" name="parent" rules={[{ required: true, message: '请选择父分组' }]}>
            <NamespaceCascaderSelect
              placeholder="选择父分组"
              onChange={(value) => form.setFieldValue('parent', value)}
            />
          </Form.Item>
        </Form>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  margin: 24px;
`;
