import { ApiError, CreateIllegalImageMaterialDto, IllegalImageMaterial } from '@36node-fcp/core-sdk';
import { Form, message, Modal } from 'antd';
import styled from 'styled-components';

import { Uploader } from 'src/components/uploader';
import { GantrySelect } from 'src/features/gantry';
import { IllegalImageMaterialErrorMap } from 'src/features/illegal-image-material';
import { IllegalNameSelect } from 'src/features/illegal-type';
import { useApi } from 'src/lib/react-api';
import { fcp, passFcpApiErrors } from 'src/services';

type EditorValues = CreateIllegalImageMaterialDto & {
  name: {
    key: string;
    value: string;
  };
};

interface IllegalImageMaterialEditorProps {
  illegalImageMaterial?: IllegalImageMaterial;
  onClose: () => void;
  onFinish?: () => void;
}

export const IllegalImageMaterialEditor: React.FC<IllegalImageMaterialEditorProps> = ({
  illegalImageMaterial,
  onClose: handleClose,
  onFinish: handleFinish,
}) => {
  const isEdit = !!illegalImageMaterial;
  const [form] = Form.useForm<EditorValues>();

  const onSuccess = () => {
    message.success(isEdit ? '编辑成功' : '创建成功');
    handleClose();
    handleFinish?.();
  };

  const onFailure = (err: ApiError) => {
    const ne = passFcpApiErrors(err, IllegalImageMaterialErrorMap);
    if (ne.keys) {
      ne.keys.forEach((key) => form.setFields([{ name: key, errors: [ne.message[key]] }]));
    } else {
      message.error(ne.message as string);
    }
  };

  const [updateState, update] = useApi(fcp.updateIllegalImageMaterial, { onSuccess, onFailure });
  const [createState, create] = useApi(fcp.createIllegalImageMaterial, { onSuccess, onFailure });

  const handleOk = () => form.submit();

  const handleSubmit = async (values: EditorValues) => {
    let req = {
      gantry: values.gantry,
      banImage: values.banImage[0],
      snapshotImage: values.snapshotImage[0],
      name: values.name.value,
      code: values.name.key,
    };

    if (isEdit) {
      update({
        illegalImageMaterialId: illegalImageMaterial.id,
        body: {
          ...values,
          ...req,
        },
      });
    } else {
      create({
        body: {
          ...values,
          ...req,
        },
      });
    }
  };
  const isLoading = updateState.loading || createState.loading;
  const title = isEdit ? '编辑合成图片素材' : '新建合成图片素材';

  const initialValues = isEdit
    ? {
        ...illegalImageMaterial,
        gantry: illegalImageMaterial.gantry.id,
        name: {
          value: illegalImageMaterial.name,
          key: illegalImageMaterial.code,
          label: illegalImageMaterial.name,
        },
      }
    : {};

  return (
    <Modal title={title} open onOk={handleOk} onCancel={handleClose} confirmLoading={isLoading} width="650px">
      <Container>
        <Form
          name="illegal-image-material-editor"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={initialValues}
          autoComplete="off"
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item label="违法名称" name="name" rules={[{ required: true, message: '请填写违法名称' }]}>
            <IllegalNameSelect placeholder="请选择违法名称" labelInValue showSearch />
          </Form.Item>

          <Form.Item label="卡口名" name="gantry" rules={[{ required: true, message: '请选择卡口' }]}>
            <GantrySelect placeholder="请选择卡口" showSearch />
          </Form.Item>

          <Form.Item label="禁令标志牌" name="banImage">
            <Uploader
              isCompress
              maxImages={1}
              initImages={illegalImageMaterial?.banImage && [illegalImageMaterial.banImage]}
              setImages={(urls: string[]) => form.setFieldValue('banImage', urls)}
            />
          </Form.Item>

          <Form.Item label="违法抓拍提示牌" name="snapshotImage">
            <Uploader
              isCompress
              maxImages={1}
              initImages={illegalImageMaterial?.snapshotImage && [illegalImageMaterial.snapshotImage]}
              setImages={(urls: string[]) => form.setFieldValue('snapshotImage', urls)}
            />
          </Form.Item>
        </Form>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  margin: 24px;
`;
