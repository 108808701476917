import { Namespace } from '@36node-fcp/auth-sdk';
import { Gantry, LawEnforcePoint, Section, Subject } from '@36node-fcp/core-sdk';
import { concat, values } from 'lodash';
import { useMemo } from 'react';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { ExportModal } from 'src/components/xlsx-export-modal';
import { SummaryReportItem, useSummaryReport } from 'src/features/report';
import { useSlice } from 'src/lib/react-slice';

import { useReportSearch } from './report.context';
import SearchForm from './search.form';

type State = {
  exportVisible: boolean;
};

const initState: State = {
  exportVisible: false,
};
const reducers = {
  openExport(state: State) {
    state.exportVisible = true;
  },
  closeExport(state: State) {
    state.exportVisible = false;
  },
};

/**
 * 总报表
 */
const SummaryReport: React.FC = () => {
  const [{ exportVisible }, dispatch] = useSlice(reducers, initState);
  const [search] = useReportSearch();
  const [{ loading, result }] = useSummaryReport(search);
  const [namesapceFilters, lawEnforcePointFilters, sectionFilters] = useMemo(() => {
    const nsMap = {};
    const lawEnforcePointMap = {};
    const sectionMap = {};
    result.forEach((item) => {
      if (item.ns) {
        nsMap[item.ns.id] = { text: item.ns.name, value: item.ns.id };
      }
      if (item.lawEnforcePoint) {
        lawEnforcePointMap[item.lawEnforcePoint.id] = {
          text: item.lawEnforcePoint.name,
          value: item.lawEnforcePoint.id,
        };
      }
      if (item.section) {
        sectionMap[item.section.id] = {
          text: item.section.name,
          value: item.section.id,
        };
      }
    });
    return [values(nsMap), values(lawEnforcePointMap), values(sectionMap)];
  }, [result]);

  const columns = useMemo<AdColumnsType<SummaryReportItem>>(() => {
    const columnsPart1 = [
      {
        title: '卡口',
        dataIndex: 'gantry',
        compute: (val: Gantry) => val.name,
      },
      {
        title: '部门名',
        dataIndex: 'ns',
        compute: (val: Namespace) => val.name,
        filters: namesapceFilters,
        onFilter: (value: string, record: SummaryReportItem) => record.ns && record.ns.id === value,
      },
      {
        title: '执法点名',
        dataIndex: 'lawEnforcePoint',
        compute: (val: LawEnforcePoint) => (val ? val.name : '--'),
        filters: lawEnforcePointFilters,
        onFilter: (value: string, record: SummaryReportItem) =>
          record.lawEnforcePoint && record.lawEnforcePoint.id === value,
      },
      {
        title: '路段名',
        dataIndex: 'section',
        compute: (val: Section) => (val ? val.name : '--'),
        filters: sectionFilters,
        onFilter: (value: string, record: SummaryReportItem) => record.section && record.section.id === value,
      },
    ];
    const columnsPart2 = [
      {
        title: '违法数',
        dataIndex: 'illegalCount',
      },
      {
        title: '预警数',
        dataIndex: 'warningCount',
      },
      {
        title: '查处数',
        dataIndex: 'warningCloseCount',
      },
    ];
    return search.subject.includes(Subject.VEHICLE)
      ? concat(
          columnsPart1,
          [
            {
              title: '过车数',
              dataIndex: 'trackCount',
            },
          ],
          columnsPart2
        )
      : concat(columnsPart1, columnsPart2);
  }, [search, namesapceFilters, lawEnforcePointFilters, sectionFilters]);

  return (
    <>
      <AdTable
        bordered
        columns={columns}
        title={<div>总报表（{result.length}）</div>}
        rowKey="uniqKey"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={result}
        showColumnsFilter={false}
        onExport={dispatch.openExport}
        pagination={false}
        extraTools={<SearchForm />}
      />
      {exportVisible && (
        <ExportModal
          dataSource={result}
          columns={columns}
          filename="总报表.xlsx"
          onClose={dispatch.closeExport}
          title="总报表"
          total={result.length}
        />
      )}
    </>
  );
};

export default SummaryReport;
