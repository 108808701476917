import {
  Gantry,
  IllegalAggregationGroup,
  IllegalType,
  LawEnforcePoint,
  Section,
  Subject,
  TrackAggregationGroup,
  WarningAggregationGroup,
  WarningState,
} from '@36node-fcp/core-sdk';
import { concat, map, reduce, uniq } from 'lodash';
import { useCallback, useMemo } from 'react';

import { ROOT_NS } from 'src/config';
import { useGantryList } from 'src/features/gantry';
import { useIllegalRecordAggregation } from 'src/features/illegal';
import { useTrackRecordAggregation } from 'src/features/track-record';
import { useNamespaceList } from 'src/features/users';
import { useWarningsAggregation } from 'src/features/warning';
import { Namespace } from 'src/sdk';

import { useIllegalTypeList } from './illegal-type';

export type ReportRequest = {
  createAt_gt?: string;
  createAt_lt?: string;
  subject?: Subject[];
};

export type SummaryReportItem = {
  uniqKey: string;
  gantry: Gantry;
  ns: Namespace;
  lawEnforcePoint: LawEnforcePoint;
  section: Section;
  illegalCount: number;
  trackCount: number;
  warningCount: number;
  warningCloseCount: number;
};

/**
 * 构建总报表数据列表
 * @param gantries 全部的卡口
 * @param namespaces 全部的部门
 * @param illegalAggregations 违法统计数据
 * @param trackAggregations 过车统计数据
 * @param warningAggregations 预警统计数据
 * @param investigateAggregations 查处统计数据
 * @param subject 违法主体
 * @returns SummaryReportItem[]
 */
export const buildListForSummaryReport = ({
  gantries,
  namespaces,
  illegalAggregations,
  trackAggregations,
  warningAggregations,
  investigateAggregations,
  subject,
}: {
  gantries?: Gantry[];
  namespaces?: Namespace[];
  illegalAggregations?: any[];
  trackAggregations?: any[];
  warningAggregations?: any[];
  investigateAggregations?: any[];
  subject?: Subject[];
}): SummaryReportItem[] => {
  const gantryIds = uniq(
    concat(
      map(illegalAggregations, 'gantry'),
      subject && subject.includes(Subject.VEHICLE) ? map(trackAggregations, 'gantry') : [],
      map(warningAggregations, 'gantry'),
      map(investigateAggregations, 'gantry')
    )
  );
  const list: SummaryReportItem[] = [];
  gantryIds.forEach((gantryId) => {
    const gantry = gantries?.find((item) => item.id === gantryId);
    if (gantry) {
      list.push({
        uniqKey: gantryId,
        gantry: gantry,
        ns: namespaces?.find((item) => item.id === gantry?.ns),
        lawEnforcePoint: gantry.lawEnforcePoint,
        section: gantry.section,
        illegalCount: illegalAggregations?.find((item) => item.gantry === gantryId)?.count,
        trackCount:
          subject && subject.includes(Subject.VEHICLE)
            ? trackAggregations?.find((item) => item.gantry === gantryId)?.count
            : 0,
        warningCount: warningAggregations?.find((item) => item.gantry === gantryId)?.count,
        warningCloseCount: investigateAggregations?.find((item) => item.gantry === gantryId)?.count,
      });
    }
  });
  return list;
};

export type LawEnforcePointReportItem = {
  uniqKey: string;
  lawEnforcePoint: LawEnforcePoint;
  illegalCount: number;
  trackCount: number;
  warningCount: number;
  warningCloseCount: number;
};

/**
 * 构建执法点报表数据列表
 * @param items SummaryReportItem
 * @returns LawEnforcePointReportItem[]
 */
export const buildListForLawEnforcePointReport = (items: SummaryReportItem[]): LawEnforcePointReportItem[] => {
  const duplicateList: LawEnforcePointReportItem[] = items.map((item) => ({
    uniqKey: item.gantry?.lawEnforcePoint?.id,
    lawEnforcePoint: item.gantry?.lawEnforcePoint,
    illegalCount: item.illegalCount,
    trackCount: item.trackCount,
    warningCount: item.warningCount,
    warningCloseCount: item.warningCloseCount,
  }));
  const uniqList: LawEnforcePointReportItem[] = [];
  const uniqKeys = uniq(map(duplicateList, 'uniqKey'));
  uniqKeys.forEach((uniqKey) => {
    if (uniqKey) {
      const sameKeyData = duplicateList.filter((item) => item.uniqKey === uniqKey);
      uniqList.push({
        uniqKey,
        lawEnforcePoint: duplicateList.find((item) => item.uniqKey === uniqKey).lawEnforcePoint,
        illegalCount: reduce(sameKeyData, (sum, item) => sum + (item.illegalCount ?? 0), 0),
        trackCount: reduce(sameKeyData, (sum, item) => sum + (item.trackCount ?? 0), 0),
        warningCount: reduce(sameKeyData, (sum, item) => sum + (item.warningCount ?? 0), 0),
        warningCloseCount: reduce(sameKeyData, (sum, item) => sum + (item.warningCloseCount ?? 0), 0),
      });
    }
  });
  return uniqList;
};

export type SectionReportItem = {
  uniqKey: string;
  section: Section;
  illegalCount: number;
  trackCount: number;
  warningCount: number;
  warningCloseCount: number;
};

/**
 * 构建路段报表数据列表
 * @param items SummaryReportItem
 * @returns LawEnforcePointReportItem[]
 */
export const buildListForSectionReport = (items: SummaryReportItem[]): SectionReportItem[] => {
  const duplicateList: SectionReportItem[] = items.map((item) => ({
    uniqKey: item.gantry?.section?.id,
    section: item.gantry?.section,
    illegalCount: item.illegalCount,
    trackCount: item.trackCount,
    warningCount: item.warningCount,
    warningCloseCount: item.warningCloseCount,
  }));
  const uniqList: SectionReportItem[] = [];
  const uniqKeys = uniq(map(duplicateList, 'uniqKey'));
  uniqKeys.forEach((uniqKey) => {
    if (uniqKey) {
      const sameKeyData = duplicateList.filter((item) => item.uniqKey === uniqKey);
      uniqList.push({
        uniqKey,
        section: duplicateList.find((item) => item.uniqKey === uniqKey).section,
        illegalCount: reduce(sameKeyData, (sum, item) => sum + (item.illegalCount ?? 0), 0),
        trackCount: reduce(sameKeyData, (sum, item) => sum + (item.trackCount ?? 0), 0),
        warningCount: reduce(sameKeyData, (sum, item) => sum + (item.warningCount ?? 0), 0),
        warningCloseCount: reduce(sameKeyData, (sum, item) => sum + (item.warningCloseCount ?? 0), 0),
      });
    }
  });
  return uniqList;
};

/**
 * 获取总报表需要的统计数据
 * @param req 总报表查询参数
 * @returns [{ loading, result }, refresh]
 */
export const useSummaryReport = (req?: ReportRequest) => {
  const [{ result: gantries = [], loading: gantriesLoading, request: gantriesRequest }, listGantries] = useGantryList({
    _limit: 1000,
    _offset: 0,
    _sort: '-createAt',
  });

  const [{ result: namespaces = [], loading: namespacesLoading, request: namespacesRequest }, listNamespaces] =
    useNamespaceList({ ns_start: ROOT_NS, _limit: 1000 });

  const [
    { result: illegalAggregations = [], loading: illegalAggregationsLoading, request: illegalAggregationsRequest },
    listIllegalAggregations,
  ] = useIllegalRecordAggregation({
    _group: [IllegalAggregationGroup.GANTRY],
    _limit: 1000,
    ...req,
  }) as any;

  const [
    { result: trackAggregations = [], loading: trackAggregationsLoading, request: trackAggregationsRequest },
    listTrackAggregations,
  ] = useTrackRecordAggregation({
    _group: [TrackAggregationGroup.GANTRY],
    _limit: 1000,
    ...req,
  }) as any;

  const [
    { result: warningAggregations = [], loading: warningAggregationsLoading, request: warningAggregationsRequest },
    listWarningAggregations,
  ] = useWarningsAggregation({
    _group: [WarningAggregationGroup.GANTRY],
    _limit: 1000,
    ...req,
  }) as any;

  const [
    {
      result: investigateAggregations,
      loading: investigateAggregationsLoading,
      request: investigateAggregationsRequest,
    },
    listInvestigateAggregations,
  ] = useWarningsAggregation({
    _group: [WarningAggregationGroup.GANTRY],
    state: [WarningState.CLOSED],
    _limit: 1000,

    ...req,
  }) as any;

  const loading = useMemo(() => {
    return Boolean(
      gantriesLoading ||
        namespacesLoading ||
        illegalAggregationsLoading ||
        trackAggregationsLoading ||
        warningAggregationsLoading ||
        investigateAggregationsLoading
    );
  }, [
    gantriesLoading,
    namespacesLoading,
    illegalAggregationsLoading,
    trackAggregationsLoading,
    warningAggregationsLoading,
    investigateAggregationsLoading,
  ]);

  const result = useMemo(() => {
    return loading
      ? []
      : buildListForSummaryReport({
          gantries,
          namespaces,
          illegalAggregations,
          trackAggregations,
          warningAggregations,
          investigateAggregations,
          subject: req.subject,
        });
  }, [
    loading,
    gantries,
    namespaces,
    illegalAggregations,
    trackAggregations,
    warningAggregations,
    investigateAggregations,
  ]);
  const refresh = useCallback(() => {
    listGantries(gantriesRequest);
    listNamespaces(namespacesRequest);
    listIllegalAggregations(illegalAggregationsRequest);
    listTrackAggregations(trackAggregationsRequest);
    listWarningAggregations(warningAggregationsRequest);
    listInvestigateAggregations(investigateAggregationsRequest);
  }, [
    gantriesRequest,
    namespacesRequest,
    illegalAggregationsRequest,
    trackAggregationsRequest,
    warningAggregationsRequest,
    illegalAggregationsRequest,
  ]);
  return [{ loading, result }, refresh] as const;
};

export type NamespaceReportItem = {
  uniqKey: string;
  ns: Namespace;
  illegalCount: number;
  trackCount: number;
  warningCount: number;
  warningCloseCount: number;
  children?: NamespaceReportItem;
};

/**
 * 将部门列表转换为树结构
 * @param nodes 部门列表
 * @param parent 父级部门
 * @returns NamespaceReportItem[]
 */
const listToTree = (nodes: NamespaceReportItem[], parent?: string): any[] => {
  return nodes
    .filter((node) => node.ns.parent === parent)
    .map((node) => {
      const children = listToTree(nodes, node.ns.id);
      if (children.length > 0) {
        return {
          ...node,
          illegalCount: reduce(children, (sum, item) => sum + (item.illegalCount ?? 0), node.illegalCount ?? 0),
          trackCount: reduce(children, (sum, item) => sum + (item.trackCount ?? 0), node.trackCount ?? 0),
          warningCount: reduce(children, (sum, item) => sum + (item.warningCount ?? 0), node.warningCount ?? 0),
          warningCloseCount: reduce(
            children,
            (sum, item) => sum + (item.warningCloseCount ?? 0),
            node.warningCloseCount ?? 0
          ),
          children,
        };
      }
      return node;
    });
};

/**
 * 构建部门报表数据列表
 * @param namespaces 全部的部门
 * @param illegalAggregations 违法统计数据
 * @param trackAggregations 过车统计数据
 * @param warningAggregations 预警统计数据
 * @param investigateAggregations 查处统计数据
 * @param subject 违法主体
 * @returns SummaryReportItem[]
 */
const buildListForNamespaceReport = (
  namespaces: Namespace[],
  illegalAggregations: any[],
  trackAggregations: any[],
  warningAggregations: any[],
  investigateAggregations: any[],
  subject: Subject[]
): NamespaceReportItem[] => {
  const nsIds = uniq(
    concat(
      map(illegalAggregations, 'ns'),
      subject.includes(Subject.VEHICLE) ? map(trackAggregations, 'ns') : [],
      map(warningAggregations, 'ns'),
      map(investigateAggregations, 'ns')
    )
  );
  const list: NamespaceReportItem[] = [];
  nsIds.forEach((nsId) => {
    const ns = namespaces?.find((item) => item.id === nsId);
    if (ns) {
      list.push({
        uniqKey: nsId,
        ns: ns,
        illegalCount: illegalAggregations?.find((item) => item.ns === nsId)?.count,
        trackCount: subject.includes(Subject.VEHICLE) ? trackAggregations?.find((item) => item.ns === nsId)?.count : 0,
        warningCount: warningAggregations?.find((item) => item.ns === nsId)?.count,
        warningCloseCount: investigateAggregations?.find((item) => item.ns === nsId)?.count,
      });
    }
  });
  return list;
};

/**
 * 获取部门报表需要的统计数据
 * @param req 部门报表查询参数
 * @returns [{ loading, list, result, total }, refresh]
 */
export const useNamespaceReport = (req?: ReportRequest) => {
  const [{ result: namespaces = [], loading: namespacesLoading, request: namespacesRequest }, listNamespaces] =
    useNamespaceList({ ns_start: ROOT_NS, _limit: 1000 });

  const [
    { result: illegalAggregations = [], loading: illegalAggregationsLoading, request: illegalAggregationsRequest },
    listIllegalAggregations,
  ] = useIllegalRecordAggregation({
    _group: [IllegalAggregationGroup.NS],
    _limit: 1000,
    ...req,
  }) as any;

  const [
    { result: trackAggregations = [], loading: trackAggregationsLoading, request: trackAggregationsRequest },
    listTrackAggregations,
  ] = useTrackRecordAggregation({
    _group: [TrackAggregationGroup.NS],
    _limit: 1000,
    ...req,
  }) as any;

  const [
    { result: warningAggregations = [], loading: warningAggregationsLoading, request: warningAggregationsRequest },
    listWarningAggregations,
  ] = useWarningsAggregation({
    _group: [WarningAggregationGroup.NS],
    _limit: 1000,
    ...req,
  }) as any;

  const [
    {
      result: investigateAggregations,
      loading: investigateAggregationsLoading,
      request: investigateAggregationsRequest,
    },
    listInvestigateAggregations,
  ] = useWarningsAggregation({
    _group: [WarningAggregationGroup.NS],
    state: [WarningState.CLOSED],
    _limit: 1000,
    ...req,
  }) as any;

  const loading = useMemo(() => {
    return Boolean(
      namespacesLoading ||
        illegalAggregationsLoading ||
        trackAggregationsLoading ||
        warningAggregationsLoading ||
        investigateAggregationsLoading
    );
  }, [
    namespacesLoading,
    illegalAggregationsLoading,
    trackAggregationsLoading,
    warningAggregationsLoading,
    investigateAggregationsLoading,
  ]);

  const result = useMemo(() => {
    return loading
      ? []
      : buildListForNamespaceReport(
          namespaces,
          illegalAggregations,
          trackAggregations,
          warningAggregations,
          investigateAggregations,
          req.subject
        );
  }, [loading, namespaces, illegalAggregations, trackAggregations, warningAggregations, investigateAggregations]);
  const refresh = useCallback(() => {
    listNamespaces(namespacesRequest);
    listIllegalAggregations(illegalAggregationsRequest);
    listTrackAggregations(trackAggregationsRequest);
    listWarningAggregations(warningAggregationsRequest);
    listInvestigateAggregations(investigateAggregationsRequest);
  }, [
    namespacesRequest,
    illegalAggregationsRequest,
    trackAggregationsRequest,
    warningAggregationsRequest,
    illegalAggregationsRequest,
  ]);
  return [
    {
      loading,
      list: result,
      result: listToTree(result, map(result, 'uniqKey').includes(ROOT_NS) ? undefined : ROOT_NS),
      total: result.length,
    },
    refresh,
  ] as const;
};

export type IllegalTypeReportItem = {
  uniqKey: string;
  illegalType: IllegalType;
  illegalCount: number;
  warningCount: number;
  warningCloseCount: number;
};

/**
 * 构建违法类型报表数据列表
 * @param illegalTypes 全部的违法类型
 * @param illegalAggregations 违法统计数据
 * @param warningAggregations 预警统计数据
 * @param investigateAggregations 查处统计数据
 * @returns IllegalTypeReportItem[]
 */
const buildListForIllegalTypeReport = (
  illegalTypes: IllegalType[],
  illegalAggregations: any[],
  warningAggregations: any[],
  investigateAggregations: any[]
): IllegalTypeReportItem[] => {
  const illegalTypeCodes = uniq(
    concat(map(illegalAggregations, 'code'), map(warningAggregations, 'code'), map(investigateAggregations, 'code'))
  );
  const list: IllegalTypeReportItem[] = [];
  illegalTypeCodes.forEach((illegalTypeCode) => {
    const illegalType = illegalTypes?.find((item) => item.code === illegalTypeCode);
    list.push({
      uniqKey: illegalTypeCode,
      illegalType,
      illegalCount: illegalAggregations?.find((item) => item.code === illegalTypeCode)?.count,
      warningCount: warningAggregations?.find((item) => item.code === illegalTypeCode)?.count,
      warningCloseCount: investigateAggregations?.find((item) => item.code === illegalTypeCode)?.count,
    });
  });
  return list;
};

/**
 * 获取违法类型报表需要的统计数据
 * @param req 违法类型报表查询参数
 * @returns [{ loading, result }, refresh]
 */
export const useIllegalTypeReport = (req?: ReportRequest) => {
  const [{ result: illegalTypes = [], loading: illegalTypesLoading, request: illegalTypesRequest }, listIllegalTypes] =
    useIllegalTypeList({ _limit: 1000 });

  const [
    { result: illegalAggregations = [], loading: illegalAggregationsLoading, request: illegalAggregationsRequest },
    listIllegalAggregations,
  ] = useIllegalRecordAggregation({
    _group: [IllegalAggregationGroup.CODE],
    _limit: 1000,
    ...req,
  }) as any;

  const [
    { result: warningAggregations = [], loading: warningAggregationsLoading, request: warningAggregationsRequest },
    listWarningAggregations,
  ] = useWarningsAggregation({
    _group: [WarningAggregationGroup.CODE],
    _limit: 1000,
    ...req,
  }) as any;

  const [
    {
      result: investigateAggregations,
      loading: investigateAggregationsLoading,
      request: investigateAggregationsRequest,
    },
    listInvestigateAggregations,
  ] = useWarningsAggregation({
    _group: [WarningAggregationGroup.CODE],
    state: [WarningState.CLOSED],
    _limit: 1000,
    ...req,
  }) as any;

  const loading = useMemo(() => {
    return Boolean(
      illegalTypesLoading || illegalAggregationsLoading || warningAggregationsLoading || investigateAggregationsLoading
    );
  }, [illegalTypesLoading, illegalAggregationsLoading, warningAggregationsLoading, investigateAggregationsLoading]);

  const result = useMemo(() => {
    return loading
      ? []
      : buildListForIllegalTypeReport(illegalTypes, illegalAggregations, warningAggregations, investigateAggregations);
  }, [loading, illegalTypes, illegalAggregations, warningAggregations, investigateAggregations]);
  const refresh = useCallback(() => {
    listIllegalTypes(illegalTypesRequest);
    listIllegalAggregations(illegalAggregationsRequest);
    listWarningAggregations(warningAggregationsRequest);
    listInvestigateAggregations(investigateAggregationsRequest);
  }, [illegalTypesRequest, illegalAggregationsRequest, warningAggregationsRequest, illegalAggregationsRequest]);
  return [{ loading, result, total: result.length }, refresh] as const;
};
