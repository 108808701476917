import { AggVehiclePropsStatResult, AggVehiclePropStatRequest, VehicleProp } from '@36node-fcp/core-sdk';
import { get } from 'lodash';
import { useMemo, useState } from 'react';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { getTimeDistance } from 'src/components/card-range-picker/card-range-picker';
import { ExportModal } from 'src/components/xlsx-export-modal';
import { useGantryList } from 'src/features/gantry';
import { toVehiclePropertyText } from 'src/features/illegal';
import { useAggVehiclePropStat } from 'src/features/vehicle';
import { useSlice } from 'src/lib/react-slice';

import { useVehicleSearch } from './vehicle.context';
import SearchForm from './vehicle.search.form';

type State = {
  exportVisible: boolean;
};

const initState: State = {
  exportVisible: false,
};
const reducers = {
  openExport(state: State) {
    state.exportVisible = true;
  },
  closeExport(state: State) {
    state.exportVisible = false;
  },
};

const defaultQuery: AggVehiclePropStatRequest = {
  time_gte: getTimeDistance('yesterday')[0].toISOString(),
  time_lte: getTimeDistance('yesterday')[1].toISOString(),
};

const toQuery = (values): AggVehiclePropStatRequest => {
  return { ...defaultQuery, time_gte: values.createAt_gt, time_lte: values.createAt_lt };
};

const VehiclePropertyGantryPage: React.FC = () => {
  const [{ exportVisible }, dispatch] = useSlice(reducers, initState);
  const [search] = useVehicleSearch();

  const [gantryFilter, setGantryFilter] = useState<string | null>();

  const [{ loading, result }] = useAggVehiclePropStat(
    toQuery({
      ...search,
    })
  );
  const [{ result: gantries = [] }] = useGantryList({ _limit: 1000 });

  const columns = useMemo<AdColumnsType<AggVehiclePropsStatResult>>(
    () => [
      {
        title: '卡口',
        dataIndex: 'gantry',
        width: 400,
        compute: (gantryId) => {
          const gantry = gantries.find((g) => g.id === gantryId);
          return gantry ? gantry.name : '';
        },
        rules: [
          { required: true, message: '请选择卡口' },
          {
            validator: (rule, val) => Boolean(gantries.find((item) => item.id === val)),
            message: '卡口无效',
          },
        ],
        filters: gantries.map((item) => ({ text: item.name, value: item.id })),
        filterSearch: true,
      },
      ...Object.keys(VehicleProp).map((prop) => ({
        title: toVehiclePropertyText(prop),
        key: prop,
        compute: (text, record) => {
          const found = record.propItems.find((p) => p.vehicleProperty === prop);
          return found ? found.value : 0;
        },
      })),
    ],
    [gantries]
  );

  const total = result?.length || 0;

  return (
    <>
      <AdTable
        columns={columns}
        title={<div>按卡口 （{total}条）</div>}
        rowKey="gantry"
        loading={loading}
        dataSource={result?.filter((item) => (gantryFilter ? gantryFilter.includes(item.gantry) : true))}
        showColumnsFilter={false}
        pagination={false}
        extraTools={<SearchForm showTime />}
        onExport={dispatch.openExport}
        onChange={(values) => {
          setGantryFilter(get(values, 'gantry'));
        }}
      />
      {exportVisible && (
        <ExportModal
          dataSource={result}
          columns={columns}
          filename="车辆使用性质按卡口报表.xlsx"
          onClose={dispatch.closeExport}
          title="车辆使用性质按卡口报表"
          total={total}
        />
      )}
    </>
  );
};

export default VehiclePropertyGantryPage;
