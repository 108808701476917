import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Modal, Progress, Upload } from 'antd';
import type { RcFile } from 'antd/es/upload';
import Compressor from 'compressorjs';
import React, { useEffect, useRef, useState } from 'react';

import { IMAGE_COMPRESS_THRESHOLD_SIZE } from 'src/config';
import { useGantryList } from 'src/features/gantry';
import { useIllegalTypeList } from 'src/features/illegal-type';
import { useApi } from 'src/lib/react-api';
import { getFileNameUUID, getFileSuffix, mutiload } from 'src/lib/s3';
import { fcp } from 'src/services';

interface IllegalImageMaterialImportProps {
  open: boolean;
  onClose: () => void;
  onFinish?: () => void;
}

const compressImage = (file: RcFile) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.6,
      convertSize: 5000,
      convertTypes: ['image/png'],
      success: (result) => {
        resolve(result);
      },
      error: (err) => {
        reject(err);
      },
    });
  });
};

export const IllegalImageMaterialImport: React.FC<IllegalImageMaterialImportProps> = ({
  open,
  onClose: handleClose,
  onFinish: handleFinish,
}) => {
  const [visible, setVisible] = useState(open);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileList, setFilelist] = useState([]);
  const fileListRef = useRef([]);

  const [{ result: types = [] }] = useIllegalTypeList({ _limit: 100 });
  const [{ result: gantries = [] }] = useGantryList({ _limit: 1000 });
  const [, upsert] = useApi(fcp.upsertIllegalImageMaterial, {
    onSuccess: (data, req) => {
      const imageUrl = req.body.banImage || req.body.snapshotImage;
      const file = fileListRef.current.find((f) => f.status === 'updating' && f.url === imageUrl);
      file.status = 'done';
      updateStates(file);
    },
    onFailure: (err, req) => {
      const imageUrl = req.body.banImage || req.body.snapshotImage;
      const file = fileListRef.current.find((f) => f.status === 'updating' && f.url === imageUrl);
      file.status = 'error';
      updateStates(file);
    },
  });

  useEffect(() => {
    setVisible(open);
  }, [open]);

  const getParams = (fileName) => {
    const regex = /^(1|2)-([0-9]+)-(.+)\.(jpg|jpeg|png)$/i;
    const match = fileName.match(regex);

    if (match) {
      const imageType = match[1];

      const code = match[2];
      const name = types.find((t) => t.code === code)?.name;
      if (!name) return null;

      const gantryName = match[3];
      const gantry = gantries.find((g) => g.name === gantryName)?.id;
      if (!gantry) return null;

      const extension = match[4];
      if (!['jpg', 'jpeg', 'png'].includes(extension)) return null;

      return {
        _imageType: imageType,
        _code: code,
        _name: name,
        _gantry: gantry,
      };
    } else {
      return null;
    }
  };

  const resetStates = () => {
    fileListRef.current = [];
    setFilelist([]);
    setProgress(0);
    setUploading(false);
  };

  const updateStates = (file) => {
    fileListRef.current = fileListRef.current.map((f) => {
      if (f.name === file.name) {
        return file;
      }

      return f;
    });

    const totalCount = fileListRef.current.length;
    const processingCount = fileListRef.current.filter(
      (f) => f.status === 'uploading' || f.status === 'updating'
    ).length;
    const errorCount = fileListRef.current.filter((f) => f.status === 'error').length;

    const percentage = Math.round(((totalCount - processingCount) / totalCount) * 100);
    setProgress(percentage);
    processingCount > 0 ? setUploading(true) : setUploading(false);

    setFilelist(fileListRef.current);

    if (percentage === 100) {
      if (errorCount > 0) {
        message.error(`${errorCount} 张图片导入失败`);
      } else {
        message.success(`${totalCount} 张图片导入成功`);
      }
    }
  };

  const handleOk = () => {
    handleClose();
    resetStates();
    handleFinish();
  };

  const handleCancel = () => {
    handleClose();
  };

  const beforeUpload = (file, fileList) => {
    const params = getParams(file.name);

    if (!params) {
      file.status = 'removed';

      if (fileList.filter((f) => f.status !== 'removed').length === 0) {
        message.error('没有符合要求的图片');
      }

      return false;
    }

    Object.assign(file, params);
    fileListRef.current.push(file);
  };

  const customRequest = async (options: any) => {
    const { file, onSuccess, onProgress, onError } = options;

    file.status = 'uploading';
    updateStates(file);

    const objName = getFileNameUUID() + getFileSuffix(file.name);

    let finalFile = file;

    if (file.size / 1024 > IMAGE_COMPRESS_THRESHOLD_SIZE) {
      finalFile = (await compressImage(file)) as RcFile;
    }

    const res = await mutiload(`${objName}`, finalFile, onProgress);
    if (!res.errCode) {
      file.url = res.url;
      file.status = 'updating';
      updateStates(file);

      const req = {
        gantry: file._gantry,
        name: file._name,
        code: file._code,
      };
      // @ts-ignore
      file._imageType === '1' ? (req.banImage = file.url) : (req.snapshotImage = file.url);

      upsert({
        body: {
          ...req,
        },
      });

      onSuccess(res, finalFile);
    } else {
      file.status = 'error';
      updateStates(file);

      onError(res.error);
    }
  };

  return (
    <Modal
      title="上传图片素材"
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="确认"
      cancelText="取消"
      width="450px"
      confirmLoading={uploading}
      maskClosable={false}
      keyboard={false}
    >
      <div style={{ paddingTop: '20px' }}>
        <Upload
          name="file"
          listType="picture"
          beforeUpload={beforeUpload}
          fileList={fileList}
          showUploadList={false}
          directory
          customRequest={customRequest}
          disabled={uploading}
        >
          <Button icon={<UploadOutlined />}>上传文件夹</Button>
        </Upload>
        <p>请选择文件夹进行上传，文件夹内应包含 .jpg 格式的图片。</p>
        <p>图片名称格式为: 1-违法代码-卡口名，或 2-违法代码-卡口名。1代表禁令标志牌，2代表违法抓拍提示牌。</p>
        <div style={{ visibility: uploading || progress > 0 ? 'visible' : 'hidden' }}>
          <Progress percent={progress} />
        </div>
      </div>
    </Modal>
  );
};
