import { ApiError, CreateIllegalTypeDto, IllegalType, ListIllegalTypesRequest, Subject } from '@36node-fcp/core-sdk';
import { Button, Divider, Form, Input, message, Popconfirm } from 'antd';
import { Rule } from 'async-validator';
import { AxiosError } from 'axios';
import { useMemo } from 'react';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { ExportModal } from 'src/components/xlsx-export-modal';
import { ImportModal } from 'src/components/xlsx-import-modal';
import { VEHICLE_ONLY } from 'src/config';
import { BooleanLabelList, boolToCN, cnToBool } from 'src/features/common';
import {
  IllegalCategoryList,
  IllegalLevelBadge,
  IllegalLevelFilter,
  IllegalLevelTextList,
  IllegalTypeApiErrorMap,
  SubjectFilter,
  SubjectTextList,
  toIllegalLevelText,
  toIllegalLevelValue,
  toSubjectLabel,
  toSubjectValue,
  useIllegalTypeList,
} from 'src/features/illegal-type';
import { useSearch } from 'src/hook/search';
import { useApi } from 'src/lib/react-api';
import { useSlice } from 'src/lib/react-slice';
import { ImportValidateError } from 'src/lib/react-xlsx';
import { fcp, passFcpApiErrors } from 'src/services';

import { IllegalTypeEditor } from './illegal-type.editor';

type SearchValues = {} & ListIllegalTypesRequest;
type SearchFormProps = {
  onSearch: (values: SearchValues) => void;
  initialValues?: SearchValues;
};

const defaultQuery: ListIllegalTypesRequest = { _limit: 10, _offset: 0, _sort: '-createAt' };

const SearchForm: React.FC<SearchFormProps> = ({ onSearch, initialValues }) => {
  return (
    <Form onFinish={onSearch} initialValues={initialValues} layout={'inline'}>
      <Form.Item name="name_like">
        <Input placeholder="违法名查询" allowClear />
      </Form.Item>
    </Form>
  );
};

type State = {
  importVisible: boolean;
  exportVisible: boolean;
  editorVisible: boolean;
  editIllegalType?: IllegalType;
};

const initState: State = {
  importVisible: false,
  exportVisible: false,
  editorVisible: false,
};

const reducers = {
  openImport(state: State) {
    state.importVisible = true;
  },
  closeImport(state: State) {
    state.importVisible = false;
  },
  openExport(state: State) {
    state.exportVisible = true;
  },
  closeExport(state: State) {
    state.exportVisible = false;
  },
  openEditor(state: State, illegalType?: IllegalType) {
    state.editorVisible = true;
    state.editIllegalType = illegalType;
  },
  closeEditor(state: State) {
    state.editorVisible = false;
    state.editIllegalType = undefined;
  },
};

const upload = async (doc: CreateIllegalTypeDto) => {
  let req = {};
  if (VEHICLE_ONLY) {
    req = { subject: Subject.VEHICLE };
  }
  return fcp.upsertIllegalType({ body: { ...doc, ...req } }).catch((err: AxiosError<ApiError>) => {
    throw passFcpApiErrors(err.response.data, IllegalTypeApiErrorMap);
  });
};
// 校验文件内是否有违法代码重复，我们也可以在这里对数据进行变换，如果有需要的话
const beforeUpload = async (records: CreateIllegalTypeDto[]) => {
  const codeSet = new Set();
  const nameSet = new Set();
  const errors: ImportValidateError[] = [];
  records.forEach(({ code, name }, index) => {
    if (codeSet.has(code)) {
      errors.push({
        row: index + 2,
        message: '文件内违法代码重复',
      });
    }
    if (nameSet.has(name)) {
      errors.push({
        row: index + 2,
        message: '文件内违法名称重复',
      });
    }
    codeSet.add(code);
    nameSet.add(name);
  });
  return errors;
};

const toQuery = (values: SearchValues): ListIllegalTypesRequest => {
  return { ...defaultQuery, ...values };
};

/**
 * 违法类型管理页面
 */
const IllegalTypePage: React.FC = () => {
  const [search, setSearch] = useSearch<SearchValues>();
  const [{ result, loading, request = {}, total }, listIllegalTypes] = useIllegalTypeList(toQuery(search));
  const { _limit: limit = 10, _offset: offset = 0 } = request;
  const [{ importVisible, exportVisible, editorVisible, editIllegalType }, dispatch] = useSlice(reducers, initState);

  const deleteOptions = useMemo(
    () => ({
      onSuccess: () => {
        message.success('删除成功');
        listIllegalTypes(request);
      },
      onFailure: (err) => {
        message.error(`删除失败: ${err.message}`);
      },
    }),
    [request]
  );
  const [deleteState, deleteIllegalType] = useApi(fcp.deleteIllegalType, deleteOptions);

  // 搜索、分页、排序触发
  const handleSearch = (values: SearchValues) => {
    setSearch({
      ...search, // 上一次留存的查询条件
      _offset: 0, // 重置页码
      ...values, // 本次输入的查询条件，取消的条件用 undefined 覆盖上一次的查询条件
    });
  };

  const handleRefresh = () => listIllegalTypes(request);

  const columns = useMemo<AdColumnsType<IllegalType>>(
    () =>
      [
        {
          title: '违法名称',
          dataIndex: 'name',
          rules: [{ required: true, message: '请填写违法名称' }],
        },
        {
          title: '违法分类',
          dataIndex: 'category',
          rules: [{ required: true, message: '请正确填写违法分类', type: 'enum', enum: IllegalCategoryList }] as Rule,
        },
        {
          title: '违法代码',
          dataIndex: 'code',
          rules: [{ required: true, message: '请填写违法代码' }],
          import: String,
        },
        !VEHICLE_ONLY && {
          title: '违法主体',
          dataIndex: 'subject',
          rules: [{ required: true, message: '请正确填写违法主体', type: 'enum', enum: SubjectTextList }] as Rule,
          filters: SubjectFilter,
          compute: toSubjectLabel,
          import: toSubjectValue,
          defaultFilteredValue: search.subject && [].concat(search.subject),
        },
        {
          title: '预警等级',
          dataIndex: 'level',
          render: (val) => <IllegalLevelBadge level={val} />,
          rules: [{ required: true, message: '请正确填写预警等级', type: 'enum', enum: IllegalLevelTextList }] as Rule,
          filters: IllegalLevelFilter,
          filterMultiple: false,
          compute: toIllegalLevelText,
          import: toIllegalLevelValue,
          defaultFilteredValue: search.level && [].concat(search.level),
        },
        {
          title: '有效天数',
          dataIndex: 'expireDay',
          rules: [{ required: true, message: '有效天数必须是正整数', type: 'integer', min: 0 }] as Rule,
          import: Number,
        },
        {
          title: '生成预警',
          dataIndex: 'genWarning',
          defaultHidden: true,
          compute: boolToCN,
          import: cnToBool,
          rules: [{ required: true, message: '生成预警填写是或否', type: 'enum', enum: BooleanLabelList }] as Rule,
        },
        {
          title: '操作',
          render: (node, record) => (
            <>
              <Button type="link" style={{ padding: 0 }} onClick={() => dispatch.openEditor(record)}>
                编辑
              </Button>
              <Divider type="vertical" />
              <Popconfirm
                title="你确定要删除这行内容吗？"
                onConfirm={() => deleteIllegalType({ illegalTypeId: record.id })}
              >
                <Button type="link" danger style={{ padding: 0 }} loading={deleteState.loading}>
                  删除
                </Button>
              </Popconfirm>
            </>
          ),
        },
      ].filter((v) => v),
    [dispatch, deleteIllegalType, search]
  );

  return (
    <>
      <AdTable
        columns={columns}
        title="违法类型管理"
        rowKey="id"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={result}
        onAddNew={() => dispatch.openEditor()}
        onChange={handleSearch}
        onRefresh={handleRefresh}
        onExport={dispatch.openExport}
        onUpload={dispatch.openImport}
        pagination={{
          total,
          current: offset / limit + 1,
          pageSize: limit,
        }}
        extraTools={<SearchForm onSearch={handleSearch} initialValues={search} />}
      />
      {exportVisible && (
        <ExportModal
          api={fcp.listIllegalTypes}
          args={request}
          columns={columns}
          filename="违法类型.xlsx"
          onClose={dispatch.closeExport}
          title="违法类型导出"
          total={total}
        />
      )}
      {importVisible && VEHICLE_ONLY && (
        <ImportModal
          api={upload}
          isSupportConcurrency
          concurrentNumber={5}
          beforeUpload={beforeUpload}
          columns={columns}
          template="违法类型管理导入模板(仅车辆).xlsx"
          onClose={dispatch.closeImport}
          onFinish={handleRefresh}
          title="违法类型导入"
        />
      )}
      {importVisible && !VEHICLE_ONLY && (
        <ImportModal
          api={upload}
          isSupportConcurrency
          concurrentNumber={5}
          beforeUpload={beforeUpload}
          columns={columns}
          template="违法类型管理导入模板.xlsx"
          onClose={dispatch.closeImport}
          onFinish={handleRefresh}
          title="违法类型导入"
        />
      )}
      {editorVisible && (
        <IllegalTypeEditor onClose={dispatch.closeEditor} onFinish={handleRefresh} illegalType={editIllegalType} />
      )}
    </>
  );
};

export default IllegalTypePage;
