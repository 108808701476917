import { ListNamespacesRequest, ListUsersRequest, Namespace } from '@36node-fcp/auth-sdk';
import { Cascader, CascaderProps } from 'antd';
import { last, reduce } from 'lodash';

import { ROOT_NS } from 'src/config';
import { useQuery } from 'src/lib/react-api';
import { auth } from 'src/services';

export type NamespaceTreeNode = Namespace & {
  children?: Namespace[];
};

export type NamespaceSelectProps = CascaderProps & {
  value?: string | string[];
  defaultValue?: string | string[];
  onChange?: (value: string | string[]) => void;
};

/**
 * Api Error
 */
export const UserApiErrorMap: { [key: string]: { [key: string]: string } } = {
  DUPLICATE: {
    username: '用户名已存在',
  },
  USER_OR_PASSWORD_ERROR: {
    oldPassword: '密码错误',
  },
};

export const NamespaceApiErrorMap: { [key: string]: { [key: string]: string } } = {
  DUPLICATE: {
    id: '分组ID已存在',
  },
};

export function listToTree(nodes: Namespace[], parent?: string): any[] {
  return nodes
    .filter((node) => node.parent === parent)
    .map((node) => ({
      id: node.id,
      text: node.key,

      // Tree
      key: node.ns,
      title: node.name,

      // CascaderSelect
      value: node.ns,

      children: listToTree(nodes, node.ns),
      disabled: false,
      selectable: true,
    }));
}

/**
 * 获取单个部门
 * @param ns 部门id
 * @returns [apiState, getNamespace]
 */
export const useNamespace = (ns: string) => {
  const [state, getNamespace] = useQuery(auth.getNamespace, { namespaceIdOrNs: ns });
  return [state, getNamespace] as const;
};

/**
 * Namespace Provider
 * 方便直接使用 namespace
 */
export const NamespaceProvider: React.FC<{
  ns: string;
  children: (namespace: Namespace | undefined) => any;
}> = ({ ns, children }) => {
  const [{ result }] = useNamespace(ns);
  return children(result);
};

/**
 * 获取分组列表
 * @param req 分组列表请求参数
 * @returns [apiState, listNamespaces]
 */
export const useNamespaceList = (req: ListNamespacesRequest) => {
  const [state, listNamespaces] = useQuery(auth.listNamespaces, req || {});
  return [state, listNamespaces] as const;
};
export const useSubNamespaceList = () => {
  const [{ result: namespaces = [] }] = useNamespaceList({});
  return namespaces.filter((ns) => ns.parent === ROOT_NS);
};

export function toCascaderValue(val: string | string[], multiple = false) {
  if (!val) return [];
  if (multiple) return (val as string[]).map((val) => toCascaderValue(val as string));
  return reduce(
    (val as string).split('.'),
    (accumulator, value) => {
      if (accumulator.length === 0) {
        accumulator.push(value);
      } else {
        const previousValue = accumulator[accumulator.length - 1];
        accumulator.push(`${previousValue}.${value}`);
      }
      return accumulator;
    },
    []
  );
}

export function fromCascaderValue(val: string[] | string[][], multiple = false) {
  if (multiple) return (val as string[][]).map((val) => fromCascaderValue(val as string[]));
  return last(val as string[]);
}

export function NamespaceCascaderSelect(props: NamespaceSelectProps) {
  const [{ result: mamespaces = [] }] = useNamespaceList({ _limit: 1000, ns_start: ROOT_NS });
  const treeData = listToTree(mamespaces);
  return (
    <Cascader
      placeholder="所属部门"
      changeOnSelect
      expandTrigger="hover"
      {...props}
      options={treeData}
      onChange={(value) => props.onChange?.(fromCascaderValue(value, props.multiple))}
      value={toCascaderValue(props.value, props.multiple)}
      defaultValue={toCascaderValue(props.defaultValue, props.multiple)}
      fieldNames={{ label: 'title', value: 'key', children: 'children' }}
    />
  );
}

/**
 * 获取用户列表
 * @param req 用户列表请求参数
 * @returns [state, listUsers]
 */
export const useUserList = (req?: ListUsersRequest) => {
  // 防止用户查询超出权限
  // if (!req.ns_start && !req.ns) {
  //   req.ns_like = ROOT_NS;
  // }

  const [state, listUsers] = useQuery(auth.listUsers, req || {});
  return [state, listUsers] as const;
};
