export interface ApiError {
  code?: ApiErrors.Code;
  details?: ApiErrors.Detail[];
  message: string;
}

export interface PassApiError {
  code?: string;
  keys?: string[];
  value?: any;
  message: { [key: string]: string } | string;
}

export enum ApiErrorCode {
  DUPLICATE = 'DUPLICATE',
}

export declare namespace ApiErrors {
  enum Code {
    DUPLICATE = 'DUPLICATE',
  }
  export interface Detail {
    message: string;
    field: string;
  }
}
