import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Select, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { styled } from 'styled-components';

import { useApi } from 'src/lib/react-api';
import { HealthStatType, RobotAPIClient } from 'src/sdk/robot-sdk';

export const HealthStatTypeData = {
  [HealthStatType.ILLEGAL_RECORD]: {
    title: '违法记录告警',
    columns: ['车牌号', '违法代码'],
    type: 'warn',
  },
  [HealthStatType.TRACK_RECORD]: {
    title: '过车记录告警',
    columns: ['车牌号'],
    type: 'warn',
  },
  [HealthStatType.WARNING]: {
    title: '预警告警',
    columns: ['车牌号', '违法代码'],
    type: 'warn',
  },
  [HealthStatType.ANALYSIS]: {
    title: '二次分析告警',
    columns: ['车牌号'],
    type: 'warn',
  },

  [HealthStatType.ILLEGAL_RECORD_INFO]: {
    title: '违法记录信息',
    columns: ['车牌号', '违法代码'],
    type: 'info',
  },
  [HealthStatType.WARNING_INFO]: {
    title: '预警信息',
    columns: ['车牌号', '违法代码'],
    type: 'info',
  },
  [HealthStatType.SETTING_INFO]: {
    title: '设置信息',
    columns: ['配置类型'],
    type: 'info',
  },
};

enum SetProperty {
  GANTRY = 'GANTRY',
  GANTRY_DEVICE = 'GANTRY_DEVICE',
  ILLEGAL_TYPE = 'ILLEGAL_TYPE',
  LAW_ENFORCE_POINT = 'LAW_ENFORCE_POINT',
  SECTION = 'SECTION',
}

const SetPropertyUi = {
  [SetProperty.GANTRY]: '卡口',
  [SetProperty.GANTRY_DEVICE]: '卡口设备',
  [SetProperty.ILLEGAL_TYPE]: '违法类型',
  [SetProperty.LAW_ENFORCE_POINT]: '执法点',
  [SetProperty.SECTION]: '路段',
};

type RobotLogCardProps = {
  client: RobotAPIClient;
  type: HealthStatType;
  defaultShow?: boolean;
};

const timeCol = {
  title: '时间',
  dataIndex: 'createAt',
};
const dataCol = {
  title: '数据详情',
  dataIndex: 'record',
  render: (val) => (
    <Typography.Text style={{ maxWidth: 500 }} ellipsis={{ tooltip: { title: val, overlayStyle: { minWidth: 600 } } }}>
      {val}
    </Typography.Text>
  ),
};

const optionalCols = [
  {
    title: '车牌号',
    dataIndex: 'plate',
    getData: (record) => get(record, 'data.plate') || get(record, 'data.plateNum'),
  },
  {
    title: '违法代码',
    dataIndex: 'property',
    getData: (record) => get(record, 'data.code') || get(record, 'data.recType'),
  },
  {
    title: '配置类型',
    dataIndex: 'property',
    getData: (record) => SetPropertyUi[get(record, 'property')],
  },
];

export const RobotWarnLogCard: React.FC<RobotLogCardProps> = ({ client, type, defaultShow = true }) => {
  const typeData = HealthStatTypeData[type];
  const columns = typeData?.columns.map((key) => optionalCols.find((c) => c.title === key));

  const [show, setShow] = useState(defaultShow);
  const [limit, setLimit] = useState(10);
  const [{ result: logs }, getLogs] = useApi(client.log);

  const fetchLogs = (limit) => {
    getLogs({
      type,
      start: dayjs().subtract(1, 'hour').toISOString(),
      end: dayjs().toISOString(),
      limit,
    });
  };

  const onChangeLimit = (value) => {
    setLimit(value);
  };

  const onPressEnter = (e) => {
    let value = parseInt(e?.target?.value, 10);
    if (value || value === 0) {
      if (value < 10) {
        value = 10;
      } else if (value > 50) {
        value = 50;
      }

      setLimit(value);
      fetchLogs(value);
    }
  };

  useEffect(() => {
    fetchLogs(limit);
  }, []);

  const datas = useMemo(() => {
    return logs?.map((log, index) => {
      const data = {
        ...log,
        data: JSON.parse(log.data),
      };
      const ret = {
        key: index,
        createAt: dayjs(log.createAt).format('YYYY-MM-DD HH:mm:ss'),
        record: log.data,
      };
      columns.forEach((col) => {
        ret[col.dataIndex] = col.getData(data);
      });
      return ret;
    });
  }, [logs]);

  return (
    <Container>
      <div className="top">
        <div className="top-left">
          <div className="title">{typeData?.title}</div>
          <Button
            type="link"
            onClick={() => {
              setShow(!show);
            }}
          >
            {show ? <UpOutlined /> : <DownOutlined />} {show ? '折叠' : '展开'}
          </Button>
        </div>
        <InputNumber value={limit} onChange={onChangeLimit} onPressEnter={onPressEnter} min={10} max={50} />
      </div>

      {show && (
        <Table
          bordered
          dataSource={datas}
          columns={[timeCol, ...columns, dataCol]}
          pagination={false}
          size="small"
          locale={{ emptyText: '--' }}
        />
      )}
    </Container>
  );
};

export const RobotInfoLogCard: React.FC<RobotLogCardProps> = ({ client, type, defaultShow = true }) => {
  const typeData = HealthStatTypeData[type];
  const columns = typeData?.columns.map((key) => optionalCols.find((c) => c.title === key));

  const [form] = Form.useForm();

  const [show, setShow] = useState(defaultShow);
  const [{ result: logs }, getLogs] = useApi(client.log);

  const fetchLogs = ({ property = undefined, limit = 10 }) => {
    getLogs({
      type,
      property,
      start: dayjs().subtract(1, 'hour').toISOString(),
      end: dayjs().toISOString(),
      limit,
    });
  };

  const onFinish = (values) => {
    console.log(values);
    fetchLogs(values);
  };

  useEffect(() => {
    fetchLogs({});
  }, []);

  const datas = useMemo(() => {
    return logs?.map((log, index) => {
      const data = {
        ...log,
        data: JSON.parse(log.data),
      };
      const ret = {
        key: index,
        createAt: dayjs(log.createAt).format('YYYY-MM-DD HH:mm:ss'),
        record: log.data,
      };
      columns.forEach((col) => {
        ret[col.dataIndex] = col.getData(data);
      });

      console.log(ret);
      return ret;
    });
  }, [logs]);

  return (
    <Container>
      <div className="top">
        <div className="top-left">
          <div className="title">{typeData?.title}</div>
          <Button
            type="link"
            onClick={() => {
              setShow(!show);
            }}
          >
            {show ? <UpOutlined /> : <DownOutlined />} {show ? '折叠' : '展开'}
          </Button>
        </div>
        <Form form={form} layout="inline" initialValues={{ limit: 10 }} onFinish={onFinish}>
          {type === HealthStatType.SETTING_INFO && (
            <Form.Item name="property">
              <Select
                options={Object.entries(SetPropertyUi).map(([key, text]) => ({ value: key, label: text }))}
                placeholder="配置类型"
                allowClear
              />
            </Form.Item>
          )}
          {type !== HealthStatType.SETTING_INFO && (
            <Form.Item name="property">
              <Input placeholder="违法代码" allowClear />
            </Form.Item>
          )}
          <Form.Item name="limit">
            <InputNumber min={10} max={50} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              查询
            </Button>
          </Form.Item>
        </Form>
      </div>

      {show && (
        <Table
          bordered
          dataSource={datas}
          columns={[timeCol, ...columns, dataCol]}
          pagination={false}
          size="small"
          locale={{ emptyText: '--' }}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 24px;

  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    .top-left {
      display: flex;
      align-items: center;

      .title {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
`;
