import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import pLimit from 'p-limit';
import { useCallback } from 'react';

import { MAX_TABLE_RECORDS } from 'src/config';
import { useSlice } from 'src/lib/react-slice';
import { Response } from 'src/sdk';

export enum ExportStatus {
  INIT = 'INIT',
  FETCHING = 'FETCHING',
  DOWNLOADING = 'DOWNLOADING',
  ZIPPING = 'ZIPPING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export type ExportImageData = {
  url: string;
  name?: string; // 图片的文件名，可选
};

export type ResultToImages<T> = (result: T[]) => ExportImageData[];

export type ExportState = {
  total: number; // 总数
  progress: number; // 进度
  status: ExportStatus; // 导出状态
  error?: any; // 错误信息
};

const initialState: ExportState = {
  progress: 0,
  total: 0,
  status: ExportStatus.INIT,
};

const reducers = {
  fetch: (state: ExportState) => {
    state.status = ExportStatus.FETCHING;
  },
  progress: (state: ExportState, { current, total }: { current: number; total: number }) => {
    state.progress = Math.floor((current / total) * 100);
    state.total = total;
  },
  downloading: (state: ExportState) => {
    state.status = ExportStatus.DOWNLOADING;
  },
  zipping: (state: ExportState) => {
    state.status = ExportStatus.ZIPPING;
  },
  success: (state: ExportState) => {
    state.status = ExportStatus.SUCCESS;
  },
  failure: (state: ExportState, error: any) => {
    state.status = ExportStatus.FAILURE;
    state.error = error;
  },
  reset: (state: ExportState) => {
    state.progress = 0;
    state.total = 0;
    state.status = ExportStatus.INIT;
    state.error = undefined;
  },
};

export const useExport = <K = any, T extends Object = any>(
  resultToImages: ResultToImages<T>,
  options?: { onSuccess?: () => void; onFailure?: (error: any) => void },
  list?: (params: K) => Response<T[]>,
  dataSource?: any[]
) => {
  const [state, actions] = useSlice(reducers, initialState);

  const call = useCallback(
    async (args: K, filename?: string) => {
      let page = 1;
      let result: T[] = [];
      const pageSize = 1000;

      async function _func(list: (params: K) => Response<T[]>, args: K) {
        const res = await list({ ...args, _limit: pageSize, _offset: (page - 1) * pageSize });
        const { data, headers } = res;
        const { 'x-total-count': totalCount } = headers;
        result = result.concat(data);

        const total = Math.min(MAX_TABLE_RECORDS, parseInt(totalCount));
        actions.progress({ current: page * pageSize, total });
        if (page * pageSize < total) {
          page++;
          await _func(list, args);
        }
      }

      try {
        actions.fetch();
        if (dataSource) {
          result = dataSource;
          actions.progress({ current: result.length, total: result.length });
        } else {
          await _func(list, args);
        }

        const images: ExportImageData[] = resultToImages(result);
        actions.downloading();

        const zip = new JSZip();
        const limit = pLimit(5);
        let completed = 0;
        const totalImages = images.length;

        const downloadPromises = images.map((image) =>
          limit(async () => {
            try {
              const response = await fetch(image.url);
              if (!response.ok) {
                throw new Error(`下载图片失败: ${image.url}`);
              }
              const blob = await response.blob();
              zip.file(image.name, blob);
            } catch (err) {
              console.error(err);
            } finally {
              completed++;
              actions.progress({ current: completed, total: totalImages });
            }
          })
        );

        await Promise.all(downloadPromises);

        actions.zipping();
        const zipBlob = await zip.generateAsync({ type: 'blob', compression: 'STORE' }, (metadata) => {
          actions.progress({ current: Math.floor(metadata.percent), total: 100 });
        });

        saveAs(zipBlob, filename || 'images.zip');

        actions.success();
        options?.onSuccess?.();
      } catch (e) {
        actions.failure(e);
        options?.onFailure?.(e);
      }
    },
    [list, actions, options, dataSource, resultToImages]
  );

  return [state, call, actions.reset] as const;
};
