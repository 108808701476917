import { UpdateMyPasswordRequest } from '@36node-fcp/auth-sdk';
import { Alert, Button, Form, Input, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { PASSWORD_EXPIRED_DAYS } from 'src/config';
import { UserApiErrorMap } from 'src/features/users';
import { useApi } from 'src/lib/react-api';
import { auth, passAuthApiErrors } from 'src/services';

type FormValues = UpdateMyPasswordRequest & {
  nextPasswordChangeDate?: string;
};

interface ChangePasswordModalProps {
  visible: boolean;
  onClose: () => void;
}

/**
 * 修改密码弹窗
 */
const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ visible, onClose }) => {
  const [form] = Form.useForm<FormValues>();
  const navigate = useNavigate();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [nextPasswordChangeDate, setNextPasswordChangeDate] = useState<string>('');

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + PASSWORD_EXPIRED_DAYS);
    const formattedDate = `${currentDate.getFullYear()}年${currentDate.getMonth() + 1}月${currentDate.getDate()}日`;
    setNextPasswordChangeDate(formattedDate);
  }, [visible]);

  const onSuccess = () => {
    message.success('修改成功', 1, () => navigate('/logout'));
    setConfirmLoading(false);
  };

  const onFailure = (errorInfo: any) => {
    const ne = passAuthApiErrors(errorInfo, UserApiErrorMap);
    if (ne.keys) {
      ne.keys.forEach((key) => form.setFields([{ name: key, errors: [ne.message[key]] }]));
    } else {
      message.error(ne.message as string);
    }
    setConfirmLoading(false);
  };

  const [updateState, update] = useApi(auth.updateMyPassword, { onSuccess, onFailure });
  const handleChangePassword = (values: any) => {
    if (values.oldPassword === values.password) {
      form.setFields([
        {
          name: 'password',
          errors: ['新密码不能与原密码相同'],
        },
      ]);
      setConfirmLoading(false);
      return;
    }

    update({ body: { oldPassword: values.oldPassword, newPassword: values.password } });
  };

  const error = updateState.error;

  return (
    <Modal title="修改密码" open={visible} confirmLoading={confirmLoading} closable={false} footer={null} width={400}>
      <Container>
        {!form.getFieldsError().length && error && <Alert message={error.message} type="error" />}
        <Form
          name="change-password"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          onFinish={() => {
            setConfirmLoading(true);
            handleChangePassword(form.getFieldsValue(true));
          }}
          form={form}
        >
          <Form.Item label="原密码" name="oldPassword" rules={[{ required: true, message: '请填写原密码' }]}>
            <Input.Password placeholder="填写原密码" autoComplete="off" />
          </Form.Item>
          <Form.Item
            label="新密码"
            name="password"
            rules={[
              { required: true, message: '请填写新密码' },
              {
                pattern: /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W_]+$)(?![a-z\d]+$)(?![a-z\W_]+$)(?![\d\W_]+$)\S{8,}$/,
                message: '至少8位密码，包括大写、小写、数字、特殊字符中的三种',
              },
            ]}
          >
            <Input.Password placeholder="填写新密码" autoComplete="off" />
          </Form.Item>
          <Footer>
            <GrayText>下一次修改时间为：{nextPasswordChangeDate}</GrayText>
            <Button type="primary" htmlType="submit" loading={confirmLoading}>
              确认
            </Button>
          </Footer>
        </Form>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  padding-top: 16px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 16px;
`;

const GrayText = styled.div`
  color: rgba(0, 0, 0, 0.45);
  margin-right: 16px;
`;

export default ChangePasswordModal;
