import { toNumber } from 'lodash';
import { useCallback, useEffect } from 'react';

import { Response } from 'src/sdk';

import { useSlice } from '../react-slice';

export type ApiState<K = any, T = any> = {
  loading: boolean;
  fetched?: boolean;
  requestAt?: Date;
  successAt?: Date;
  failureAt?: Date;
  error?: any;
  request?: K;
  result?: T;
  total?: number;
};

const initialState = {
  loading: false,
};

const reducers = {
  request: (state: ApiState, payload: any) => {
    state.error = undefined;
    state.loading = true;
    state.request = payload;
    state.requestAt = new Date();
  },
  success: (state: ApiState, payload: any & { 'x-total-count'?: number }) => {
    state.loading = false;
    state.fetched = true;
    state.result = payload;
    state.successAt = new Date();
    state.total = payload['x-total-count'];
  },
  failure: (state: ApiState, payload: any) => {
    state.loading = false;
    state.fetched = true;
    state.error = payload;
    state.failureAt = new Date();
  },
};

type ApiOptions<T> = {
  onSuccess?: (data: T, req?: any) => void;
  onFailure?: (error: any, req?: any) => void;
};

/**
 * 通用的 useApi hook
 *
 * @param api api 方法
 * @template K 请求参数类型
 * @template T 返回结果类型
 * @returns 返回数据
 */
export const useApi = <K = any, T = any>(api: (req: K) => Response<T>, options?: ApiOptions<T>) => {
  const [state, actions] = useSlice(reducers, initialState as ApiState<K, T>);
  const { onSuccess, onFailure } = options || {};

  const call = useCallback(
    async (req: K) => {
      actions.request(req as K);

      try {
        const { data, headers } = await api(req);
        if (headers['x-total-count']) Object.assign(data, { 'x-total-count': toNumber(headers['x-total-count']) });
        actions.success(data);
        onSuccess?.(data, req);
        return data;
      } catch (err) {
        const { response } = err;
        actions.failure(response?.data || err);
        onFailure?.(response?.data || err, req);
      }
    },
    [api, onSuccess, onFailure]
  );

  return [state, call] as const;
};

export const useQuery = <K = any, T = any>(api: (req: K) => Response<T>, req: K = {} as K, options?: ApiOptions<T>) => {
  const [state, call] = useApi<K, T>(api, options);

  useEffect(() => {
    call(req);
  }, [call, JSON.stringify(req)]);

  return [state, call] as const;
};
