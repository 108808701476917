import {
  IllegalImageMaterial,
  ListIllegalImageMaterialsRequest,
  ListIllegalTypesRequest,
  Subject,
} from '@36node-fcp/core-sdk';
import { ClearOutlined } from '@ant-design/icons';
import { Button, Divider, Image, message, Popconfirm, Tooltip } from 'antd';
import { useMemo } from 'react';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { ExportModal } from 'src/components/image-export-modal';
import { ExportImageData } from 'src/components/image-export-modal/export';
import { useGantryList } from 'src/features/gantry';
import { useIllegalImageMaterial } from 'src/features/illegal-image-material';
import { useIllegalTypeList } from 'src/features/illegal-type';
import { useSearch } from 'src/hook/search';
import { useApi } from 'src/lib/react-api';
import { useSlice } from 'src/lib/react-slice';
import { fcp } from 'src/services';

import { IllegalImageMaterialEditor } from './illegal-image-material.editor';
import { IllegalImageMaterialImport } from './illegal-image-material.import';

type SearchValues = {} & ListIllegalImageMaterialsRequest;

const defaultQuery: ListIllegalImageMaterialsRequest = { _limit: 10, _offset: 0, _sort: '-createAt' };

const toQuery = (values: SearchValues): ListIllegalTypesRequest => {
  return { ...defaultQuery, ...values };
};

type State = {
  importVisible: boolean;
  editorVisible: boolean;
  exportVisible: boolean;
  editIllegalImageMaterial?: IllegalImageMaterial;
};

const initState: State = {
  importVisible: false,
  editorVisible: false,
  exportVisible: false,
};

const reducers = {
  openImport(state: State) {
    state.importVisible = true;
  },
  closeImport(state: State) {
    state.importVisible = false;
  },
  openEditor(state: State, illegalImageMaterial?: IllegalImageMaterial) {
    state.editorVisible = true;
    state.editIllegalImageMaterial = illegalImageMaterial;
  },
  closeEditor(state: State) {
    state.editorVisible = false;
    state.editIllegalImageMaterial = undefined;
  },
  openExport(state: State) {
    state.exportVisible = true;
  },
  closeExport(state: State) {
    state.exportVisible = false;
  },
};

const IllegalImageMaterialPage: React.FC = () => {
  const [search, setSearch] = useSearch<SearchValues>();

  const [{ result = [], loading, request = {}, total }, listIllegalImageMaterials] = useIllegalImageMaterial(
    toQuery(search)
  );
  const { _limit: limit = 10, _offset: offset = 0 } = request;
  const [{ result: gantries = [] }] = useGantryList({ _limit: 1000 });
  const [{ result: illegalTypes = [] }] = useIllegalTypeList({ _limit: 1000, subject: [Subject.VEHICLE] });

  const resultToImages = (result) => {
    const images: ExportImageData[] = [];
    result.forEach((item) => {
      if (item.banImage) {
        images.push({
          url: item.banImage,
          name: `1-${item.code}-${item.gantry.name}.${item.banImage.split('.').pop()}`,
        });
      }
      if (item.snapshotImage) {
        images.push({
          url: item.snapshotImage,
          name: `2-${item.code}-${item.gantry.name}.${item.snapshotImage.split('.').pop()}`,
        });
      }
    });
    return images;
  };

  const deleteOptions = useMemo(
    () => ({
      onSuccess: () => {
        message.success('删除成功');
        listIllegalImageMaterials(request);
      },
      onFailure: (err) => {
        message.error(`删除失败: ${err.message}`);
      },
    }),
    [request]
  );

  const clearOptions = useMemo(
    () => ({
      onSuccess: () => {
        message.success('清除成功');
        listIllegalImageMaterials(request);
      },
      onFailure: (err) => {
        message.error(`清除失败: ${err.message}`);
      },
    }),
    [request]
  );

  const [{ importVisible, editorVisible, exportVisible, editIllegalImageMaterial }, dispatch] = useSlice(
    reducers,
    initState
  );
  const [deleteState, deleteIllegalImageMaterial] = useApi(fcp.deleteIllegalImageMaterial, deleteOptions);
  const [clearState, clearIllegalImageMaterial] = useApi(fcp.clearIllegalImageMaterial, clearOptions);

  // 搜索、分页、排序触发
  const handleSearch = (values: SearchValues) => {
    setSearch({
      ...search, // 上一次留存的查询条件
      _offset: 0, // 重置页码
      ...values, // 本次输入的查询条件，取消的条件用 undefined 覆盖上一次的查询条件
    });
  };
  const handleRefresh = () => listIllegalImageMaterials(request);

  const columns = useMemo<AdColumnsType<IllegalImageMaterial>>(
    () => [
      {
        title: '禁令标志牌',
        dataIndex: 'banImage',
        render: (url) => {
          if (url) {
            return <Image src={url} height={60} width={60} />;
          }
        },
        align: 'center',
      },
      {
        title: '违法抓拍提示牌',
        dataIndex: 'snapshotImage',
        render: (url) => {
          if (url) {
            return <Image src={url} height={60} width={60} />;
          }
        },
        align: 'center',
      },
      {
        title: '违法名',
        dataIndex: 'name',
        rules: [
          { required: true, message: '请填写违法名称' },
          {
            validator: (rule, val) =>
              Boolean(illegalTypes.find((item) => item.name === val && item.subject === Subject.VEHICLE)),
            message: '违法名称无效',
          },
        ],
        filters: illegalTypes.map((item) => ({ text: item.name, value: item.name })),
        filterSearch: true,
        defaultFilteredValue: search.name && [].concat(search.name),
      },
      {
        title: '卡口名',
        dataIndex: 'gantry',
        compute: (gantry) => gantry?.name,
        rules: [
          { required: true, message: '请选择卡口' },
          {
            validator: (rule, val) => Boolean(gantries.find((item) => item.id === val)),
            message: '卡口无效',
          },
        ],
        filters: gantries.map((item) => ({ text: item.name, value: item.id })),
        filterSearch: true,
        defaultFilteredValue: search.gantry && [].concat(search.gantry),
      },
      {
        title: '操作',
        render: (text, record) => (
          <>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => {
                dispatch.openEditor(record);
              }}
            >
              编辑
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="确认删除该条素材吗？"
              onConfirm={() => {
                deleteIllegalImageMaterial({
                  illegalImageMaterialId: record.id,
                });
              }}
            >
              <Button type="link" danger style={{ padding: 0 }} loading={deleteState.loading}>
                删除
              </Button>
            </Popconfirm>
          </>
        ),
      },
    ],
    [dispatch, deleteIllegalImageMaterial, search]
  );

  return (
    <>
      <AdTable
        columns={columns}
        extraToolsAppend={
          <Popconfirm title="将清除所有的配置和图片，是否确认？" onConfirm={() => clearIllegalImageMaterial({})}>
            <Tooltip title="一键清除">
              <Button
                type="link"
                size="large"
                style={{
                  color: '#999',
                  padding: 0,
                  lineHeight: 0,
                }}
                loading={clearState.loading}
              >
                <ClearOutlined />
              </Button>
            </Tooltip>
          </Popconfirm>
        }
        title="合成图片素材"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={result}
        onAddNew={() => dispatch.openEditor()}
        onChange={handleSearch}
        onRefresh={handleRefresh}
        uploadTooltip="批量上传"
        onUpload={() => dispatch.openImport()}
        onExport={() => dispatch.openExport()}
        pagination={{
          total,
          current: offset / limit + 1,
          pageSize: limit,
        }}
      />
      {editorVisible && (
        <IllegalImageMaterialEditor
          onClose={dispatch.closeEditor}
          onFinish={handleRefresh}
          illegalImageMaterial={editIllegalImageMaterial}
        />
      )}

      <ExportModal
        open={exportVisible}
        resultToImages={resultToImages}
        api={fcp.listIllegalImageMaterials}
        args={request}
        filename="合成图片素材.zip"
        onClose={dispatch.closeExport}
        title="合成图片素材"
        total={total}
      />

      <IllegalImageMaterialImport open={importVisible} onClose={dispatch.closeImport} onFinish={handleRefresh} />
    </>
  );
};

export default IllegalImageMaterialPage;
