import { ApiError, CreateSectionDto, Section } from '@36node-fcp/core-sdk';
import { Alert, Form, Input, message, Modal } from 'antd';
import styled from 'styled-components';

import { SectionApiErrorMap } from 'src/features/road-section';
import { useApi } from 'src/lib/react-api';
import { fcp, passFcpApiErrors } from 'src/services';

type EditorValues = CreateSectionDto & {};

interface SectionEditorProps {
  Section?: Section;
  onClose: () => void;
  onFinish?: () => void;
}

export const RoadSectionEditor: React.FC<SectionEditorProps> = ({
  Section,
  onClose: handleClose,
  onFinish: handleFinish,
}) => {
  const isEdit = !!Section;
  const onSuccess = () => {
    message.success(isEdit ? '编辑成功' : '创建成功');
    handleClose();
    handleFinish?.();
  };

  const onFailure = (err: ApiError) => {
    const ne = passFcpApiErrors(err, SectionApiErrorMap);
    if (ne.keys) {
      ne.keys.forEach((key) => form.setFields([{ name: key, errors: [ne.message[key]] }]));
    } else {
      message.error(ne.message as string);
    }
  };

  const [form] = Form.useForm<EditorValues>();
  const [updateState, update] = useApi(fcp.updateSection, { onSuccess, onFailure });
  const [createState, create] = useApi(fcp.createSection, { onSuccess, onFailure });

  const handleOk = () => form.submit();
  const handleSubmit = async (values: EditorValues) => {
    if (isEdit) {
      update({ sectionId: Section.id, body: values });
    } else {
      create({ body: values });
    }
  };

  const isLoading = updateState.loading || createState.loading;
  const title = isEdit ? '编辑路段' : '新增路段';
  const error = updateState.error || createState.error;

  return (
    <Modal title={title} width="650px" open onOk={handleOk} onCancel={handleClose} confirmLoading={isLoading}>
      <Container>
        {error && <Alert message={error.message} type="error" />}
        <Form
          name="road-section-editor"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          initialValues={Section}
          autoComplete="off"
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item label="路段名" name="name" rules={[{ required: true, message: '请填写路段名' }]}>
            <Input placeholder="填写路段名" />
          </Form.Item>
        </Form>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  margin: 24px;
`;
