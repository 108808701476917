import { ApiError, ListIllegalImageMaterialsRequest } from '@36node-fcp/core-sdk';

import { useQuery } from 'src/lib/react-api';
import { fcp } from 'src/services';

export const useIllegalImageMaterial = (req: ListIllegalImageMaterialsRequest) => {
  const [state, listIllegalImageMaterials] = useQuery(fcp.listIllegalImageMaterials, req || {});

  return [state, listIllegalImageMaterials] as const;
};

/**
 * Api Error
 */
export const IllegalImageMaterialErrorMap: { [key: string]: { [key: string]: string } } = {
  [ApiError.Code.DUPLICATE]: {
    code: '卡口违法代码已存在',
    name: '卡口违法代码已存在',
  },
};
