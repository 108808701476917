import { RobotInfo, RobotType } from '@36node-fcp/core-sdk';
import { Layout, Menu, theme } from 'antd';
import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { useRobotList } from 'src/features/robot';
import { useSession } from 'src/features/session';

import { menuItemsReduce } from './main';

// export type CustomMenuItem = ItemType & {
//   path?: string;
//   key: string;
//   label: React.ReactNode;
// };

export function sourceRobotMenu(robotLists: RobotInfo[] = []) {
  return robotLists.map((item) => ({
    key: item.id,
    path: `/system/source/${item.id}`,
    label: <NavLink to={`source/${item.id}`}>{item.name}</NavLink>,
  }));
}

export function destRobotMenu(robotLists: RobotInfo[] = []) {
  return robotLists.map((item) => ({
    key: item.id,
    path: `/system/destination/${item.id}`,
    label: <NavLink to={`destination/${item.id}`}>{item.name}</NavLink>,
  }));
}

export const getMenuItems = (sourceRobotLists?: RobotInfo[], destRobotLists?: RobotInfo[]) => {
  return [
    {
      key: 'system-health',
      path: '/system/system-health',
      label: <NavLink to="system-health">系统概览</NavLink>,
    },
    {
      key: 'gantry',
      path: '/system/gantry',
      label: <NavLink to="gantry">卡口管理</NavLink>,
    },
    {
      key: 'device',
      path: '/system/device',
      label: <NavLink to="device">设备管理</NavLink>,
    },
    {
      key: 'law-enforce-point',
      path: '/system/law-enforce-point',
      label: <NavLink to="law-enforce-point">执法点管理</NavLink>,
    },
    {
      key: 'road-section',
      path: '/system/road-section',
      label: <NavLink to="road-section">路段管理</NavLink>,
    },
    {
      key: 'illegal-type',
      path: '/system/illegal-type',
      label: <NavLink to="illegal-type">违法类型管理</NavLink>,
    },
    {
      key: 'illegal-image-material',
      path: '/system/illegal-image-material',
      label: <NavLink to="illegal-image-material">合成图片素材</NavLink>,
    },
    {
      key: 'user',
      path: '/system/user',
      label: <NavLink to="user">用户管理</NavLink>,
    },
    {
      key: 'role',
      path: '/system/role',
      label: <NavLink to="role">角色管理</NavLink>,
    },
    {
      key: 'whitelist',
      path: '/system/whitelist',
      label: <NavLink to="whitelist">白名单管理</NavLink>,
    },
    {
      key: 'rule',
      path: '/system/rule',
      label: <NavLink to="rule">规则管理</NavLink>,
    },
    {
      key: 'source',
      path: '/system/source',
      label: (
        <NavLink to="source" end>
          数据源管理
        </NavLink>
      ),
      children: sourceRobotMenu(sourceRobotLists),
    },
    {
      key: 'destination',
      path: '/system/destination',
      label: (
        <NavLink to="destination" end>
          数据目的地管理
        </NavLink>
      ),
      children: destRobotMenu(destRobotLists),
    },
  ];
};

/**
 * 系统设置模块的布局
 *
 * 左侧菜单
 * 右侧内容
 */

const SystemLayout: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const session = useSession();
  const [{ result: sourceRobotLists = [] }] = useRobotList({ type: RobotType.SOURCE, _limit: 1000 });
  const [{ result: destRobotLists = [] }] = useRobotList({ type: RobotType.DEST, _limit: 1000 });

  const items = menuItemsReduce(session, getMenuItems(sourceRobotLists, destRobotLists));
  return (
    <Layout style={{ padding: '24px 0', background: colorBgContainer }}>
      <Layout.Sider style={{ background: colorBgContainer }} width={200}>
        <NavMenu mode="inline" openKeys={['source', 'destination']} style={{ height: '100%' }} items={items} />
      </Layout.Sider>
      <Layout.Content style={{ padding: '0 24px', minHeight: 280 }}>
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};

export default SystemLayout;

const NavMenu = styled(Menu)`
  li.ant-menu-submenu,
  li.ant-menu-item {
    overflow: visible !important;

    .ant-menu-submenu-title {
      margin-left: 0px;
      margin-right: 0px;
      padding-right: 16px;
      border-radius: 0;
    }

    span.ant-menu-title-content {
      overflow: visible !important;

      a {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 20px 0 28px;
        color: #575757;

        margin-left: -28px;
        margin-right: -20px;
      }

      a.active {
        background: #f5faff;
        color: #1890ff;
        border-right: 2px solid #1890ff;
      }
    }
  }

  li.ant-menu-submenu > .ant-menu-submenu-title,
  li.ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #aaa !important;
    font-weight: 500;

    i.ant-menu-submenu-arrow {
      display: none;
    }
  }

  ul.ant-menu-sub {
    background: inherit !important;

    li.ant-menu-item {
      span.ant-menu-title-content {
        a {
          margin-left: -52px;
          padding: 0 20px 0 52px;
        }
      }
    }
  }
`;
