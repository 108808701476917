import { FcpAPIClient } from '@36node-fcp/core-sdk';
import axios, { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

import { FCP_ENDPOINT } from 'src/config';
import { paramsSerializer } from 'src/lib/url';
import { errorHandler, injectToken } from 'src/sdk/helpers';

import { getToken } from './auth';
import { ApiError, PassApiError } from './error';

const fcpInstance = axios.create({ baseURL: FCP_ENDPOINT, paramsSerializer });
fcpInstance.interceptors.request.use(injectToken(getToken));
fcpInstance.interceptors.response.use(undefined, errorHandler);

export class Fcp extends FcpAPIClient {
  batchDeleteVehicleByIds = async (ids: string[]) => {
    const resp = await Promise.all(ids.map((id) => this.deleteVehicle({ vehicleId: id })));
    let response: AxiosResponse<void> = resp[0] as any;
    return response;
  };
}

export const fcp = new Fcp(fcpInstance);

export const passFcpApiErrors = (err: ApiError, map: { [key: string]: { [key: string]: string } }): PassApiError => {
  const { details = [], code } = err;
  const messageObj = {};
  const cacheKeys = [];
  if (!details.length || isEmpty(details)) return err;
  details.forEach(({ field, message }) => {
    field.split(',').forEach((key) => {
      cacheKeys.push(key);
      if (map[code]?.[String(key)]) {
        messageObj[key] = map[code]?.[String(key)];
      } else {
        messageObj[key] = message;
      }
    });
  });
  return {
    code,
    keys: cacheKeys,
    message: messageObj,
  };
};
