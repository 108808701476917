import { MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Divider, Drawer, Empty, Space } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { styled } from 'styled-components';
import useSound from 'use-sound';

// @ts-ignore
import WarningAudio from 'src/assets/audios/warning.wav';
import { ReactComponent as NotificationMute } from 'src/assets/notification-mute.svg';
import { ReactComponent as NotificationUnmute } from 'src/assets/notification-unmute.svg';
import { WatchWarningMode, WatchWarningModeSelect } from 'src/features/warning';
import { useSlice } from 'src/lib/react-slice';

import { useMonitor } from './monitor.provider';
import { WarningCard } from './warning-card';

type WarningDrawProps = {
  open: boolean;
  onClose: () => void;
};

type State = {
  watchMode: WatchWarningMode;
};

const initState: State = {
  watchMode: WatchWarningMode.ALL,
};

const reducer = {
  setWatchMode: (state: State, watchMode: WatchWarningMode) => {
    state.watchMode = watchMode;
  },
};

export const WarningDraw: React.FC<WarningDrawProps> = (props) => {
  const [{ settings, warnings }, monitorDispatch] = useMonitor();
  const [{ watchMode }, dispatch] = useSlice(reducer, initState);

  const [play] = useSound(WarningAudio);
  const playWarningAudio = () => {
    if (!settings.mute) play();
  };

  useEffect(() => {
    playWarningAudio();
  }, [warnings]);

  return (
    <WarningDrawer
      open={props.open}
      placement="right"
      width={460}
      mask={false}
      getContainer={null}
      closable={false}
      zIndex={990}
    >
      <WarningsDrawerBox>
        <div className="header">
          <Space align="center">
            <Button
              type="text"
              icon={<MenuUnfoldOutlined style={{ fontSize: 22, transform: 'translateY(2px)', opacity: 0.6 }} />}
              onClick={() => {
                props.onClose();
              }}
            />
            <WatchWarningModeSelect style={{ width: 166 }} onChange={dispatch.setWatchMode} />

            <MuteToggelButton onClick={() => monitorDispatch.set({ mute: !settings.mute })}>
              {settings.mute ? (
                <NotificationMute style={{ width: 22, height: 22, fill: '#727272' }} />
              ) : (
                <NotificationUnmute style={{ width: 22, height: 22, fill: '#727272' }} />
              )}
            </MuteToggelButton>
          </Space>
        </div>

        <Divider style={{ margin: '12px 0' }} />

        <div className="warnings">
          {isEmpty(warnings) ? (
            <Empty description="暂无数据" />
          ) : (
            warnings[watchMode].map((warning) => <WarningCard warning={warning} key={warning.id} />)
          )}
        </div>
      </WarningsDrawerBox>
    </WarningDrawer>
  );
};

const WarningDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 76px 0 12px !important;
    overflow-x: hidden !important;
  }
`;

const WarningsDrawerBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .header {
    padding: 12px 24px;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 4px;
  }

  .warnings {
    padding: 0 24px;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

const MuteToggelButton = styled.button`
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  background: #fff;
  color: #727272;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
`;
