import { FcpAPIClient as AuthClient, LoginRequest, SessionWithToken } from '@36node-fcp/auth-sdk';
import axios from 'axios';
import { get, isEmpty } from 'lodash';

import { AUTH_ENDPOINT } from 'src/config';
import { paramsSerializer } from 'src/lib/url';
import { errorHandler, injectToken } from 'src/sdk/helpers';

import { ApiError, PassApiError } from './error';

const SESSION_KEY = 'session_key';
const SESSION = 'session';

export const getSessionKey = () => sessionStorage.getItem(SESSION_KEY) || localStorage.getItem(SESSION_KEY);
export const saveSessionKey = (sessionKey: string) => localStorage.setItem(SESSION_KEY, sessionKey);
export const removeSessionKey = () => localStorage.removeItem(SESSION_KEY);

export const saveSession = (session: SessionWithToken) => sessionStorage.setItem(SESSION, JSON.stringify(session));
export const getSession = (): SessionWithToken | null => JSON.parse(sessionStorage.getItem(SESSION) || 'null');
export const removeSession = () => sessionStorage.removeItem(SESSION);
export const getToken = () => get(getSession(), 'token');

const authInstance = axios.create({ baseURL: AUTH_ENDPOINT, paramsSerializer });
authInstance.interceptors.request.use(injectToken(getToken));
authInstance.interceptors.response.use(undefined, errorHandler);

export const auth = new AuthClient(authInstance);

export const logout = async () => {
  try {
    await auth.logout({ body: { key: getSession()?.key } });
  } catch (e) {
    throw e;
  } finally {
    removeSession();
    removeSessionKey();
  }
  return null;
};

export const login = async (req: LoginRequest) => {
  const res = await auth.login(req);
  saveSession(res.data);
  saveSessionKey(res.data.key);
  return res;
};

export const passAuthApiErrors = (err: ApiError, map: { [key: string]: { [key: string]: string } }): PassApiError => {
  const { details = [], code } = err;
  const messageObj = {};
  const cacheKeys = [];
  if (!details.length || isEmpty(details)) return err;
  details.forEach(({ field, message }) => {
    field.split(',').forEach((key) => {
      cacheKeys.push(key);
      if (map[code]?.[String(key)]) {
        messageObj[key] = map[code]?.[String(key)];
      } else {
        messageObj[key] = message;
      }
    });
  });
  return {
    code,
    keys: cacheKeys,
    message: messageObj,
  };
};
