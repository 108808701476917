import { Subject } from '@36node-fcp/core-sdk';
import { Flex } from 'antd';
import dayjs from 'dayjs';

import {
  CardRangePicker,
  CardRangeTimePickerByHour,
  getTimeDistance,
  RangePickerValue,
  TimeType,
} from 'src/components/card-range-picker';

import { useVehicleSearch } from './vehicle.context';

type SearchFormProps = {
  onSubjectSelect?: (value: Subject) => void;
  onDateRangeChange?: (value: RangePickerValue) => void;
  showTime?: boolean;
};
const SearchForm: React.FC<SearchFormProps> = ({ showTime }) => {
  const [{ rangePickerValue }, dispatch] = useVehicleSearch();
  const isActive = (type: TimeType) => {
    if (!rangePickerValue) {
      return '';
    }
    const value = getTimeDistance(type);
    if (!value) {
      return '';
    }
    if (!rangePickerValue[0] || !rangePickerValue[1]) {
      return '';
    }
    if (
      rangePickerValue[0].isSame(value[0] as dayjs.Dayjs, 'day') &&
      rangePickerValue[1].isSame(value[1] as dayjs.Dayjs, 'day')
    ) {
      return 'currentDate';
    }
    return '';
  };

  const selectDate = (type: TimeType) => {
    const dates = getTimeDistance(type);
    dispatch.setDateRange(dates);
  };

  const selectDateRange = (dates: RangePickerValue) => {
    dispatch.setDateRange(dates);
  };

  const selectDateHour = (type: TimeType) => {
    const dates = getTimeDistance(type);
    dispatch.setDateRangeHour(dates);
  };

  const selectDateRangeHour = (dates: RangePickerValue) => {
    dispatch.setDateRangeHour(dates);
  };

  return (
    <Flex gap="24">
      {!showTime && (
        <CardRangePicker
          rangePickerValue={rangePickerValue}
          isActive={isActive}
          showTime={showTime}
          noSpace={true}
          handleRangePickerChange={selectDateRange}
          selectDate={selectDate}
        />
      )}

      {showTime && (
        <CardRangeTimePickerByHour
          rangePickerValue={rangePickerValue}
          isActive={isActive}
          noSpace={true}
          handleRangePickerChange={selectDateRangeHour}
          selectDate={selectDateHour}
          showTimeFormat="HH"
        />
      )}
    </Flex>
  );
};

export default SearchForm;
