import { Subject } from '@36node-fcp/core-sdk';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import styled from 'styled-components';

import { MAP_CENTER, MAP_CURRENT_ZOOM, MAP_MAX_ZOOM, MAP_MIN_ZOOM } from 'src/config';
import { GantryCascaderSelect } from 'src/features/gantry';
import { MapBounds, MapUrl } from 'src/features/map';
import { useSlice } from 'src/lib/react-slice';

import { BatchModal } from './batch.modal';
import { GantryDetailDraw } from './gantry-detail.drawer';
import { GantryModal } from './gantry.modal';
import { MarkerLayer } from './marker-layer';
import { WarningDrawerTrigger } from './warning-drawer-trigger';
import { WarningLayer } from './warning-layer';

type State = {
  searchGantryId: string;
  showGantryDrawer: boolean;
  showGantryModal: boolean;
  selectedSubject: Subject[] | undefined;
  showBatchModal: boolean;
};

const initialState: State = {
  searchGantryId: '',
  showGantryDrawer: false,
  showGantryModal: false,
  selectedSubject: undefined,
  showBatchModal: false,
};

const reduers = {
  setSearchGantryId: (state: State, searchGantryId: string) => {
    state.searchGantryId = searchGantryId;
  },
  openBatchModal: (state: State) => {
    state.showGantryModal = false;
    state.showBatchModal = true;
  },
  closeBatchModal: (state: State) => {
    state.showBatchModal = false;
  },
  openGantryDrawer: (state: State, gantryId: string) => {
    state.showGantryDrawer = true;
    state.searchGantryId = gantryId;
  },
  closeGantryDrawer: (state: State) => {
    state.showGantryDrawer = false;
    state.searchGantryId = '';
  },
  openGantryModal: (state: State, subject: Subject[]) => {
    state.showBatchModal = false;
    state.showGantryModal = true;
    state.selectedSubject = subject;
  },
  closeGantryModal: (state: State) => {
    state.showGantryModal = false;
  },
};

const MonitorPage: React.FC = () => {
  const [{ searchGantryId, showGantryDrawer, showGantryModal, selectedSubject, showBatchModal }, dispatch] = useSlice(
    reduers,
    initialState
  );

  return (
    <Container>
      <SearchBox>
        <GantryCascaderSelect
          size="large"
          placeholder="查询卡口"
          style={{ width: '80%', height: '100%' }}
          onChange={dispatch.openGantryDrawer as any}
        />
      </SearchBox>
      <MapContainer
        closePopupOnClick={false}
        zoomControl={false}
        center={MAP_CENTER}
        zoom={MAP_CURRENT_ZOOM}
        scrollWheelZoom={true}
        style={{ height: '100%', width: '100%' }}
        bounceAtZoomLimits={true}
        maxBoundsViscosity={0.95}
        maxBounds={MapBounds}
      >
        <TileLayer url={MapUrl} minZoom={MAP_MIN_ZOOM} maxZoom={MAP_MAX_ZOOM} />
        <ZoomControl position="bottomleft" />
        <WarningLayer />
        <MarkerLayer searchGantryId={searchGantryId} onClick={(gantry) => dispatch.openGantryDrawer(gantry.id)} />
      </MapContainer>
      <WarningDrawerTrigger onClick={dispatch.openBatchModal} />
      {showGantryDrawer && (
        <GantryDetailDraw
          gantryId={searchGantryId}
          onClose={dispatch.closeGantryDrawer}
          onOpenGantryModal={dispatch.openGantryModal}
        />
      )}
      {showGantryModal && (
        <GantryModal
          selectedGantryId={searchGantryId}
          onClose={dispatch.closeGantryModal}
          selectedSubject={selectedSubject}
        />
      )}
      {showBatchModal && <BatchModal onClose={dispatch.closeBatchModal} />}
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  top: 64px;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  a[href^= 'https://leafletjs.com']
  {
    display: none;
  }
  overflow: hidden;
`;
const SearchBox = styled.div`
  position: absolute;
  top: 24px;
  left: 32px;
  width: 458px;
  height: 48px;
  z-index: 900;
  max-height: 800px;
`;

export default MonitorPage;
