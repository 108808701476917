import { UpdateMyPasswordRequest } from '@36node-fcp/auth-sdk';
import { Alert, Button, Card, Form, Input, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { UserApiErrorMap } from 'src/features/users';
import { useApi } from 'src/lib/react-api';
import { auth, passAuthApiErrors } from 'src/services';

type FormValues = UpdateMyPasswordRequest & {};

/**
 * 修改密码页面
 */
const ChangePasswordPage: React.FC = () => {
  const [form] = Form.useForm<FormValues>();
  const navigator = useNavigate();
  const onSuccess = () => {
    message.success('修改成功');
    navigator('/logout');
  };

  const onFailure = (errorInfo: any) => {
    const ne = passAuthApiErrors(errorInfo, UserApiErrorMap);
    if (ne.keys) {
      ne.keys.forEach((key) => form.setFields([{ name: key, errors: [ne.message[key]] }]));
    } else {
      message.error(ne.message as string);
    }
  };

  const [updateState, update] = useApi(auth.updateMyPassword, { onSuccess, onFailure });
  const handleChangePassword = (values: any) => {
    update({ body: { oldPassword: values.oldPassword, newPassword: values.password } });
  };

  const error = updateState.error;

  return (
    <>
      <Container
        title="修改密码"
        bodyStyle={{
          paddingBottom: 0,
          paddingTop: 0,
        }}
      >
        {!form.getFieldsError().length && error && <Alert message={error.message} type="error" />}
        <Form
          name="change-password"
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 4 }}
          autoComplete="off"
          onFinish={() => handleChangePassword(form.getFieldsValue(true))}
          form={form}
        >
          <Form.Item label="原密码" name="oldPassword" rules={[{ required: true, message: '请填写原密码' }]}>
            <Input.Password placeholder="填写原密码" autoComplete="off" />
          </Form.Item>
          <Form.Item
            label="新密码"
            name="password"
            rules={[
              { required: true, message: '请填写新密码' },
              {
                pattern: /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W_]+$)(?![a-z\d]+$)(?![a-z\W_]+$)(?![\d\W_]+$)\S{8,}$/,
                message: '至少8位密码，包括大写、小写、数字、特殊字符中的三种',
              },
            ]}
          >
            <Input.Password placeholder="填写新密码" autoComplete="off" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 1 }}>
            <Button type="primary" htmlType="submit">
              确认修改
            </Button>
          </Form.Item>
        </Form>
      </Container>
    </>
  );
};

const Container = styled(Card)`
  border: none !important;
  .ant-card-head {
    border: 0 !important;
    .ant-card-extra {
      padding: 8px 0;
    }
  }
  .ant-typography {
    color: rgba(0, 0, 0, 0.65);
  }
`;

export default ChangePasswordPage;
