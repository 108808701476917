import { RobotType } from '@36node-fcp/core-sdk';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, message, Space, Tooltip, Upload } from 'antd';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import styled from 'styled-components';

import { useRobotList } from 'src/features/robot';
import { useApi } from 'src/lib/react-api';
import { fcp, getRobotClient } from 'src/services';

interface RobotManagerPageProps {
  title: string;
  type: RobotType;
}

const ExtraButtonStyle = {
  color: '#999',
  padding: 0,
  lineHeight: 0,
};

const RobotManagerPage: React.FC<RobotManagerPageProps> = ({ title, type }) => {
  const [, upsert] = useApi(fcp.register);

  const [importing, setImporting] = useState(false);
  const [exporting, setExporting] = useState(false);

  const [, listRobotInfos] = useRobotList({ type: RobotType.SOURCE, _limit: 1000 });

  const handleExport = async () => {
    setExporting(true);
    listRobotInfos({ type, _limit: 1000 })
      .then(async (res) => {
        const result = await Promise.all(
          res.map(async ({ id, createAt, updateAt, ...rest }) => {
            const robotInfo = rest as any;
            const robotClient = getRobotClient(robotInfo.baseUrl.replace(/^\/\//, 'https://'));
            try {
              const { data } = await robotClient.getSetting({});
              const { updateBy, ...setting } = data;
              robotInfo.setting = setting;
            } catch (err) {
              message.error(`获取${robotInfo.name}配置失败`);
              console.error(err);
            }
            return robotInfo;
          })
        );

        const jsonString = JSON.stringify(result, null, 2);
        const blob = new Blob([jsonString], { type: 'application/json;charset=utf-8' });
        saveAs(blob, `${title}.json`);
        message.success('导出成功');
      })
      .catch((err) => {
        message.error(err.message || '获取配置失败');
      })
      .finally(() => {
        setExporting(false);
      });
  };

  const handleFileUpload = (file: File) => {
    const isJson = file.type === 'application/json';
    if (!isJson) {
      message.error('请上传JSON文件');
      return Upload.LIST_IGNORE;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      setImporting(true);
      try {
        const result = e.target?.result;
        if (result) {
          const jsonData = JSON.parse(result as string);

          if (!Array.isArray(jsonData) || jsonData.some((item) => item.type !== String(type))) {
            throw new Error('配置文件格式不正确');
          }

          await Promise.all(
            jsonData.map(async (item) => {
              const { setting, ...robotInfo } = item;
              await upsert({
                body: robotInfo,
              });

              if (!setting) {
                return;
              }

              const robotClient = getRobotClient(robotInfo.baseUrl.replace(/^\/\//, 'https://'));
              try {
                await robotClient.updateSetting({ body: setting });
              } catch (err) {
                message.error(`更新${robotInfo.name}配置失败`);
                console.error(err);
              }
            })
          )
            .then(() => {
              message.success('导入成功');
              listRobotInfos({ type, _limit: 1000 });
            })
            .catch(() => {
              message.error('导入失败');
            });
        } else {
          throw new Error('配置文件读取失败');
        }
      } catch (error) {
        message.error(error.message || '配置文件格式不正确');
      } finally {
        setImporting(false);
      }
    };
    reader.readAsText(file);

    return false;
  };

  return (
    <Container>
      <Top>
        <div className="title">{title}</div>
        <Space>
          <Tooltip title="导入配置">
            <Upload beforeUpload={handleFileUpload} showUploadList={false} accept=".json">
              <Button type="link" size="large" style={ExtraButtonStyle} loading={importing}>
                <UploadOutlined />
              </Button>
            </Upload>
          </Tooltip>

          <Tooltip title="导出配置">
            <Button type="link" size="large" style={ExtraButtonStyle} onClick={handleExport} loading={exporting}>
              <DownloadOutlined />
            </Button>
          </Tooltip>
        </Space>
      </Top>

      <p>请参照《Robot编写指南》编写数据源Robot。</p>
      <p>Robot 启动后会自动出现在这个页面。</p>
      <p>如果 Robot 不再使用，可以在这个页面删除相关的资源。</p>
    </Container>
  );
};
export default RobotManagerPage;

const Container = styled.div`
  margin: 16px 24px;
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .title {
    font-size: 1rem;
    font-weight: 600;
    color: rgb(0, 0, 0, 0.88);
    line-height: 1.5;
  }
`;
