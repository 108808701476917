import {
  ApiError,
  CreateIllegalRecordDto,
  IllegalRecord,
  ListIllegalRecordsRequest,
  Subject,
} from '@36node-fcp/core-sdk';
import { FileTextOutlined } from '@ant-design/icons';
import { Button, Card, DatePicker, Divider, Flex, Form, Input, message, Popconfirm, Spin } from 'antd';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { isArray, isEmpty, map, min } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { ExportModal } from 'src/components/xlsx-export-modal';
import { ImportModal } from 'src/components/xlsx-import-modal';
import { MAX_BATCH_REMOVE_RECORDS, ROOT_NS } from 'src/config';
import { useIllegalDraftList } from 'src/features/draft';
import { useGantryDeviceList } from 'src/features/gantry-device';
import {
  IllegalRecordApiErrorMap,
  IllegalRecordExpireBadge,
  IllegalRecordExpireFilter,
  IllegalRecordStateBadge,
  IllegalRecordStateFilter,
  toIllegalRecordStateText,
  toVehiclePropertyText,
  toVehiclePropertyValue,
  useIllegalRecordList,
  VehiclePropertyFilter,
  VehiclePropertyUiList,
} from 'src/features/illegal';
import { useIllegalTypeList } from 'src/features/illegal-type';
import { useSession } from 'src/features/session';
import { useNamespaceList } from 'src/features/users';
import { useSearch } from 'src/hook/search';
import { XLSXDate } from 'src/lib/lang/string';
import { ymdhms } from 'src/lib/lang/time';
import { checkPermission } from 'src/lib/privilege';
import { useApi } from 'src/lib/react-api';
import { useSlice } from 'src/lib/react-slice';
import { Namespace } from 'src/sdk';
import { fcp, passFcpApiErrors } from 'src/services';

import { IllegalEditor } from './illegal.editor';

const Container = styled(Card)`
  border: none !important;
  .ant-card-head {
    border: 0 !important;
    .ant-card-extra {
      padding: 8px 0;
    }
  }
  .ant-card-body {
    margin-bottom: 24px;
  }
`;

type SearchValues = {
  createAt?: any;
  expired?: any;
} & ListIllegalRecordsRequest;

type SearchFormProps = {
  onSearch: (values: SearchValues) => void;
  initialValues?: SearchValues;
};

const defaultQuery: ListIllegalRecordsRequest = {
  _limit: 10,
  _offset: 0,
  subject: [Subject.VEHICLE],
};

// 判断页面是否操作过查询
const isSearched = (search: SearchValues) => {
  // 切换页码不算查询
  // 默认查询条件不算查询
  // 这里因为 expiredAt_gt 和 subject 不在页面上作为查询条件，所以简单排除掉即可
  const { _limit, _offset, subject, ...rest } = search;
  return !isEmpty(rest);
};

const SearchForm: React.FC<SearchFormProps> = ({ onSearch, initialValues }) => {
  return (
    <Container>
      <Form onFinish={onSearch} initialValues={initialValues} layout={'inline'}>
        <Form.Item name="plate" label="车牌：">
          <Input placeholder="车牌查询" allowClear />
        </Form.Item>
        <Form.Item name="plateType" label="号牌种类：">
          <Input placeholder="号牌种类查询" allowClear />
        </Form.Item>
        <Form.Item name="place" label="违法地点：">
          <Input placeholder="违法地点查询" allowClear />
        </Form.Item>
        <Form.Item label="入库时间：" name="createAt">
          <DatePicker.RangePicker
            showTime
            showSecond={false}
            placeholder={['开始时间', '结束时间']}
            format="YYYY-MM-DD HH:mm"
          />
        </Form.Item>
        <Form.Item shouldUpdate>
          <Button type="primary" htmlType="submit">
            查询
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

const Title: React.FC<{ total: number }> = (props) => {
  return (
    <Flex align="center">
      <div style={{ fontSize: '16px', fontWeight: '500', color: 'color: rgba(0,0,0,0.85)' }}>违法库管理</div>
      <div style={{ fontSize: '14px', color: 'rgba(0,0,0,0.25)', marginLeft: '12px' }}>
        <FileTextOutlined style={{ marginRight: '3px' }} />
        {/* <Link to="/confirm" style={{ color: 'rgba(0,0,0,0.25)' }}>
          待确认违法共 {props.total} 条
        </Link> */}
        <span style={{ color: 'rgba(0,0,0,0.25)' }}>待确认违法共 {props.total} 条</span>
      </div>
    </Flex>
  );
};

type State = {
  importVisible: boolean;
  exportVisible: boolean;
  editorVisible: boolean;
};

const initState: State = {
  importVisible: false,
  exportVisible: false,
  editorVisible: false,
};

const reducers = {
  openImport(state: State) {
    state.importVisible = true;
  },
  closeImport(state: State) {
    state.importVisible = false;
  },
  openExport(state: State) {
    state.exportVisible = true;
  },
  closeExport(state: State) {
    state.exportVisible = false;
  },
  openEditor(state: State) {
    state.editorVisible = true;
  },
  closeEditor(state: State) {
    state.editorVisible = false;
  },
};

const toQuery = (values: SearchValues): ListIllegalRecordsRequest => {
  const { createAt, expired, ...rest } = values;

  if (createAt && isArray(createAt) && createAt[0] && createAt[1]) {
    rest.createAt_gt = dayjs(createAt[0].toISOString()).startOf('minute').toISOString();
    rest.createAt_lt = dayjs(createAt[1].toISOString()).endOf('minute').toISOString();
  } else if (createAt === null) {
    rest.createAt_gt = undefined;
    rest.createAt_lt = undefined;
  }
  if (expired) {
    if (expired === 'EFFECTIVE') {
      rest.expiredAt_gt = dayjs().startOf('minute').toISOString();
      rest.expiredAt_lt = undefined;
    } else if (expired === 'EXPIRED') {
      rest.expiredAt_lt = dayjs().endOf('minute').toISOString();
      rest.expiredAt_gt = undefined;
    }
  }
  return { ...defaultQuery, ...rest };
};

/**
 * 违法库管理页面
 */
const IllegalPage: React.FC = () => {
  const navigate = useNavigate();
  const session = useSession();

  const [search, setSearch] = useSearch<SearchValues>();
  const [{ result: gantryDevices = [] }] = useGantryDeviceList({ _limit: 1000 });
  const [{ result: illegalTypes = [] }] = useIllegalTypeList({ _limit: 1000, subject: [Subject.VEHICLE] });
  const [{ result: namespaces = [] }] = useNamespaceList({ ns_start: ROOT_NS, _limit: 1000 });
  const [{ result, loading, request = {}, total }, listIllegalRecords] = useIllegalRecordList(toQuery(search));
  const [{ total: draftCount }] = useIllegalDraftList({});
  const { _limit: limit = 10, _offset: offset = 0 } = request;
  const [{ importVisible, exportVisible, editorVisible }, dispatch] = useSlice(reducers, initState);

  const deleteOptions = useMemo(
    () => ({
      onSuccess: () => {
        message.success('删除成功');
        listIllegalRecords(request);
      },
      onFailure: (err) => {
        message.error(`删除失败: ${err.message}`);
      },
    }),
    [request]
  );

  const batchDeleteOptions = useMemo(
    () => ({
      onSuccess: () => {
        message.success(`批量删除成功，本次共删除${min([total, MAX_BATCH_REMOVE_RECORDS])}条数据`);
        listIllegalRecords(request);
      },
      onFailure: (err) => {
        message.error(`批量删除失败: ${err.message}`);
      },
    }),
    [request]
  );

  const [deleteState, deleteIllegalRecord] = useApi(fcp.deleteIllegalRecord, deleteOptions);
  const [batchDeleteState, batchDeleteIllegalRecord] = useApi(fcp.batchDeleteIllegalRecords, batchDeleteOptions);

  // 搜索、分页、排序触发
  const handleSearch = (values: SearchValues) => {
    setSearch({
      ...search, // 上一次留存的查询条件
      _offset: 0, // 重置页码
      ...values, // 本次输入的查询条件，取消的条件用 undefined 覆盖上一次的查询条件
    });
  };

  const handleRefresh = () => listIllegalRecords(request);
  const handleBatchDelete = () => batchDeleteIllegalRecord(request as any);

  const upload = useCallback(
    async (doc: CreateIllegalRecordDto) => {
      const illegalType = illegalTypes.find((item) => item.name === doc.name);

      doc.level = illegalType?.level;
      doc.code = illegalType?.code;
      doc.subject = Subject.VEHICLE;
      doc.source = '网页上传';
      if (!doc.expiredAt) {
        doc.expiredAt = dayjs(doc.capAt).add(illegalType.expireDay, 'days').toISOString();
      }

      return fcp.createIllegalRecord({ body: doc }).catch((err: AxiosError<ApiError>) => {
        throw passFcpApiErrors(err.response.data, IllegalRecordApiErrorMap);
      });
    },
    [illegalTypes]
  );

  const columns = useMemo<AdColumnsType<IllegalRecord>>(
    () => [
      {
        title: '车牌',
        dataIndex: 'plate',
        rules: [{ required: true, message: '请填写车牌' }],
      },
      {
        title: '号牌种类',
        dataIndex: 'plateType',
        rules: [{ required: true, message: '请填写号牌种类' }],
      },

      {
        title: '使用性质',
        dataIndex: 'vehicleProperty',
        rules: [{ type: 'enum', enum: map(VehiclePropertyUiList, 'text'), message: '所选使用无效' }],
        filters: VehiclePropertyFilter,
        compute: toVehiclePropertyText,
        import: toVehiclePropertyValue,
        defaultFilteredValue: search.vehicleProperty && [].concat(search.vehicleProperty),
      },
      {
        title: '所属部门',
        dataIndex: 'ns',
        filters: namespaces?.map((item: Namespace) => ({ text: item.name, value: item.ns })).reverse(),
        filterMultiple: false,
        compute: (val) => namespaces?.find((item) => item.ns === val)?.name || '--',
        import: (_name: string, { gantryDevice }) => {
          return gantryDevices.find((item) => item.name === gantryDevice)?.gantry?.ns || session?.user?.ns;
        },
        defaultFilteredValue: search?.ns && [search.ns],
      },
      {
        title: '所属企业',
        dataIndex: 'vehicleCompany',
      },
      {
        title: '违法时间',
        dataIndex: 'capAt',
        compute: ymdhms,
        rules: [{ required: false, type: 'date', message: '违法时间无效' }],
        import: XLSXDate,
      },
      {
        title: '入库时间',
        dataIndex: 'createAt',
        compute: ymdhms,
        importDisabled: true,
      },
      {
        title: '违法名称',
        dataIndex: 'name',
        rules: [
          { required: true, message: '请填写违法名称' },
          {
            validator: (rule, val) =>
              Boolean(illegalTypes.find((item) => item.name === val && item.subject === Subject.VEHICLE)),
            message: '违法名称无效',
          },
        ],
        filters: illegalTypes.map((item) => ({ text: item.name, value: item.name })),
        filterSearch: true,
        defaultFilteredValue: search.name && [].concat(search.name),
      },
      {
        title: '车速(km/h)',
        dataIndex: 'carSpeed',
        rules: [{ type: 'number', min: 0, message: '车速无效' }],
        import: (val: number) => (val ? val : undefined),
        compute: (val: number) => (val ? `${val}` : '/'),
        render: (val: number) => (val ? `${val}` : '/'),
      },
      {
        title: '抓拍相机',
        dataIndex: 'gantryDevice',
        compute: (deviceId: string) => gantryDevices.find((item) => item.id === deviceId)?.name,
        rules: [{ type: 'enum', enum: map(gantryDevices, 'name'), message: '请正确填写抓拍相机' }],
        import: (name: string) => gantryDevices.find((item) => item.name === name)?.id,
      },
      {
        title: '违法地点',
        dataIndex: 'place',
      },
      {
        title: '违法来源',
        dataIndex: 'source',
      },
      {
        title: '状态',
        dataIndex: 'state',
        render: (val) => <IllegalRecordStateBadge state={val} />,
        filters: IllegalRecordStateFilter,
        compute: toIllegalRecordStateText,
        importDisabled: true,
        defaultFilteredValue: search.state && [].concat(search.state),
      },
      {
        title: '是否过期',
        key: 'expired',
        filters: IllegalRecordExpireFilter,
        defaultFilteredValue: search.expired && [].concat(search.expired),
        filterMultiple: false,
        importDisabled: true,
        compute: (_, { expiredAt }) => (expiredAt && dayjs(expiredAt).isAfter(dayjs()) ? '未过期' : '过期'),
        render: (_, { expiredAt }) =>
          expiredAt && dayjs(expiredAt).isAfter(dayjs()) ? (
            <IllegalRecordExpireBadge state={'EFFECTIVE'} />
          ) : (
            <IllegalRecordExpireBadge state={'EXPIRED'} />
          ),
      },
      {
        title: '有效天数',
        key: 'expiredAt',
        disabled: true,
        import: (val: number, record: IllegalRecord) => val && dayjs(record.capAt).add(val, 'day').toISOString(),
      },
      {
        title: '操作',
        render: (node, record) => (
          <>
            {checkPermission(session, ['vehicle:carIllegalAd']) && (
              <Popconfirm
                title="你确定要删除这行内容吗？"
                onConfirm={() => deleteIllegalRecord({ illegalRecordId: record.id })}
              >
                <Button
                  disabled={!checkPermission(session, ['vehicle:carIllegalAd'])}
                  type="link"
                  danger
                  style={{ padding: 0 }}
                  loading={deleteState.loading}
                >
                  删除
                </Button>
              </Popconfirm>
            )}
            <Divider type="vertical" />
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => {
                navigate(`${record.id}`);
              }}
            >
              详情
            </Button>
          </>
        ),
      },
    ],
    [dispatch, namespaces, gantryDevices, illegalTypes, deleteIllegalRecord, search]
  );

  if (!namespaces || !gantryDevices || !illegalTypes) {
    return <Spin />;
  }
  return (
    <>
      <SearchForm onSearch={handleSearch} initialValues={search} />
      <AdTable
        columns={columns}
        extraTools={
          checkPermission(session, ['vehicle:carIllegalAd']) && (
            <Popconfirm
              title={
                <>
                  是否将查询到的内容全部删除？
                  <br />
                  将删除{min([total, MAX_BATCH_REMOVE_RECORDS])}条数据
                </>
              }
              onConfirm={handleBatchDelete}
            >
              <Button
                disabled={!isSearched(search) || !total || loading}
                loading={batchDeleteState.loading}
                type="primary"
              >
                按查询结果删除
              </Button>
            </Popconfirm>
          )
        }
        title={<Title total={draftCount} />}
        rowKey="id"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={result}
        onAddNew={checkPermission(session, ['vehicle:carIllegalAd']) ? () => dispatch.openEditor() : null}
        onChange={handleSearch}
        onRefresh={handleRefresh}
        onExport={dispatch.openExport}
        onUpload={checkPermission(session, ['vehicle:carIllegalAd']) ? dispatch.openImport : null}
        pagination={{
          total,
          current: offset / limit + 1,
          pageSize: limit,
        }}
      />
      {exportVisible && (
        <ExportModal
          api={fcp.listIllegalRecords}
          args={request}
          columns={columns}
          filename="车辆违法库.xlsx"
          onClose={dispatch.closeExport}
          title="车辆违法库"
          total={total}
        />
      )}
      {importVisible && (
        <ImportModal
          api={upload}
          columns={columns}
          template="违法信息导入模板.xlsx"
          onClose={dispatch.closeImport}
          onFinish={handleRefresh}
          title="违法信息导入"
        />
      )}
      {editorVisible && <IllegalEditor onClose={dispatch.closeEditor} onFinish={handleRefresh} />}
    </>
  );
};

export default IllegalPage;
