import { Card, Tabs, TabsProps } from 'antd';
import styled from 'styled-components';

import VehiclePropertyGantryPage from './vehicle-property-gantry.page';
import VehiclePropertyStorePage from './vehicle-property-store.page';

/**
 * 车辆使用性质统计
 */
const VehiclePropertyReportPage: React.FC = () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: '总报表',
      children: <VehiclePropertyStorePage />,
    },
    {
      key: '2',
      label: '按卡口',
      children: <VehiclePropertyGantryPage />,
    },
  ];
  return (
    <>
      <Container
        bodyStyle={{
          paddingBottom: 0,
          paddingTop: 0,
        }}
      >
        <Tabs defaultActiveKey="1" destroyInactiveTabPane items={items} />
      </Container>
    </>
  );
};

const Container = styled(Card)`
  border: none !important;
  min-width: 1200px;
  .ant-card-head {
    border: 0 !important;
    .ant-card-extra {
      padding: 8px 0;
    }
  }
  .ant-typography {
    color: rgba(0, 0, 0, 0.65);
  }
`;

export default VehiclePropertyReportPage;
