import { IllegalAggregationGroup } from '@36node-fcp/core-sdk';
import { useCallback } from 'react';

import { toVehiclePropertyText, useIllegalRecordAggregation } from './illegal';

export type IllegalVehicleReportRequest = {
  createAt_gt?: string;
  createAt_lt?: string;
  code?: string; // 违法类型
  count_gt?: number; // 违法次数大于
};

export type IllegalVehicleReportItem = {
  count: number;
  plate: string;
  plateType?: string;
  vehicleProperty?: string;
};

export const useIllegalVehicleReport = (req?: IllegalVehicleReportRequest) => {
  const [{ result, loading, request }, listIllegalAggregations] = useIllegalRecordAggregation({
    _group: [
      IllegalAggregationGroup.PLATE,
      IllegalAggregationGroup.PLATE_TYPE,
      IllegalAggregationGroup.VEHICLE_PROPERTY,
    ],
    _limit: 1000,
    _offset: 0,
    _count_gt: req?.count_gt,
    code: [req?.code],
    createAt_gt: req?.createAt_gt,
    createAt_lt: req?.createAt_lt,
  });

  const refresh = useCallback(() => {
    listIllegalAggregations(request);
  }, [request]);

  const list: IllegalVehicleReportItem[] =
    result
      ?.filter((item) => Boolean(item['plate']))
      .map((item) => {
        return {
          count: item.count,
          plate: item['plate'],
          plateType: item['plateType'],
          vehicleProperty: toVehiclePropertyText(item['vehicleProperty']),
        };
      }) || [];

  return [{ loading, result: list, total: list?.length }, refresh] as const;
};
