import { ListRolesRequest } from '@36node-fcp/auth-sdk';
import { Select, SelectProps, Tree } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { TreeProps } from 'antd/es/tree';
import React from 'react';

import { ROOT_NS } from 'src/config';
import { Privilege, privilegeList } from 'src/lib/privilege';
import { useQuery } from 'src/lib/react-api';
import { auth } from 'src/services';

/**
 * Api Error
 */
export const RoleApiErrorMap: { [key: string]: { [key: string]: string } } = {
  DUPLICATE: {},
};

/**
 * 获取角色列表
 * @param req 角色列表请求参数
 * @returns [state, listRoles]
 */
export const useRoleList = (req?: ListRolesRequest) => {
  const [state, listRoles] = useQuery(auth.listRoles, req || {});
  return [state, listRoles] as const;
};

/**
 * 将列表转换为树结构
 */
export function listToTree(nodes: Privilege[], parent?: string, prefix?: string): any[] {
  return nodes
    .filter((node) => node.parent === parent)
    .map((node) => ({
      key: prefix ? `${prefix}:${node.value}` : node.value,
      title: node.text,
      children: listToTree(nodes, node.value, prefix ? `${prefix}:${node.value}` : node.value),
    }));
}

/**
 * 权限选择
 */
export function PrivilegeTreeSelect(props: TreeProps) {
  return <Tree treeData={listToTree(privilegeList)} checkable showLine defaultExpandAll {...props} />;
}

export const RolesSelect = ({ value, ...rest }: SelectProps) => {
  const [{ result: roles = [] }] = useRoleList({ _limit: 1000, _offset: 0, namespaceIdOrNs: ROOT_NS });
  const options = roles.filter((item) => !['USER'].includes(item.type)) as unknown as DefaultOptionType[];
  const roleTypes = options.map((item) => item.type);
  value = value?.filter((item) => roleTypes.includes(item));

  return (
    <Select
      allowClear
      mode="multiple"
      fieldNames={{ label: 'name', value: 'type' }}
      {...rest}
      value={value}
      options={options}
    />
  );
};
