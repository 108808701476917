import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Typography } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { fadeIn, zoomIn } from 'react-animations';
import { useNavigate } from 'react-router-dom';
import { keyframes, styled } from 'styled-components';

import { ROOT_NS, TITLE } from 'src/config';
import { useApi } from 'src/lib/react-api';
import { login } from 'src/services';

type FieldType = {
  username?: string;
  password?: string;
  server?: string;
};

const ten = 10 * 60;

export default function LoginPage() {
  const navigate = useNavigate();
  const [{ loading, error }, callLogin] = useApi(login, {
    onSuccess: () => {
      localStorage.removeItem('_ERROR_LOGIN_');
      navigate('/');
    },
  });
  const storageError: any = ((localStorage.getItem('_ERROR_LOGIN_') || '0_0') as string).split('_');
  const [isCanLogin, setIsCanLogin] = useState<number>(storageError[0] - 0);
  const currentTime = Math.floor(dayjs().valueOf() / 1000);
  const calCounter = ten - (currentTime - (storageError[1] - 0 || currentTime));
  const [counter, setCounter] = useState<number>(calCounter > 0 ? calCounter : 0);
  const handleLogin = (values: FieldType) => {
    if (isCanLogin >= 5 && !!counter) return;
    callLogin({
      body: { login: values.username, password: values.password, scope: ROOT_NS },
    });
  };

  useEffect(() => {
    if (error) {
      const frequency = isCanLogin + 1;
      const current = Math.floor(dayjs().valueOf() / 1000);
      if (frequency >= 5) {
        localStorage.setItem('_ERROR_LOGIN_', String(isCanLogin + 1) + '_' + current);
        setCounter(ten);
      } else {
        localStorage.setItem('_ERROR_LOGIN_', String(isCanLogin + 1) + '_' + 0);
      }
      setIsCanLogin(frequency);
    }
  }, [error]);

  useEffect(() => {
    if (counter === 0) {
      localStorage.removeItem('_ERROR_LOGIN_');
      setCounter(0);
      setIsCanLogin(0);
      return;
    }
    const timer = setTimeout(() => setCounter(counter - 1), 1000);

    return () => clearTimeout(timer);
  }, [counter]);

  return (
    <Container>
      <Top>
        <div style={{ marginBottom: 64 }}>
          <img src="/logo.png" style={{ width: 120 }} alt="logo" />
        </div>
        <Typography.Title level={2} style={{ color: '#1c488b', fontSize: 36 }}>
          {TITLE}
        </Typography.Title>
      </Top>

      <Bottom>
        {error && !(!!counter && isCanLogin >= 5) && (
          <Alert
            message="用户名或密码错误，请重新输入。"
            type="error"
            closable
            style={{ width: 400, marginBottom: 18 }}
          />
        )}
        {!!counter && isCanLogin >= 5 && (
          <Alert
            message={`系统登陆失败超过5次，请${counter}秒后重试`}
            type="error"
            closable
            style={{ width: 400, marginBottom: 18 }}
          />
        )}
        <Form autoComplete="off" requiredMark={false} style={{ width: 400 }} onFinish={handleLogin}>
          <Form.Item name="username" rules={[{ required: true, message: '请输入用户名' }]} style={{ marginBottom: 16 }}>
            <Input
              style={{ width: '100%' }}
              size="large"
              placeholder="用户名"
              prefix={<UserOutlined />}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: '请输入密码' }]}>
            <Input.Password
              style={{ width: '100%' }}
              placeholder="密码"
              prefix={<LockOutlined />}
              size="large"
              disabled={loading}
            />
          </Form.Item>

          <Form.Item style={{ marginTop: 48 }}>
            <Button
              disabled={isCanLogin >= 5 && !!counter}
              size="large"
              type="primary"
              style={{ width: '100%' }}
              htmlType="submit"
              loading={loading}
            >
              登录
            </Button>
          </Form.Item>
        </Form>
      </Bottom>
    </Container>
  );
}

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-form-item-explain-error {
    text-align: start;
  }
`;

// const SubTitle = styled.div`
//   font-size: 20px;
// `;

const zoomInAnimation = keyframes`${zoomIn}`;
const fadeInAnimation = keyframes`${fadeIn}`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg) no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;

  background: rgba(255, 255, 255, 0.25);
  margin-top: 8vh;
  display: inline-block;
  animation: 2s ${fadeInAnimation};

  h2 {
    margin-bottom: 28px;
    color: rgba(10, 10, 10, 0.85);
    font-size: 50px;
    text-shadow: 2px 2px 0px #ffffff;
    animation: 1s ${zoomInAnimation};
  }

  button {
    padding-left: 42px;
    padding-right: 42px;
    animation: 1.5s ${zoomInAnimation};
    background: #1677ff;
  }
`;
