// Generated by openapi-ts-gen. DO NOT EDIT
/* eslint-disable */
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import * as schemas from './schemas';

export class RobotAPIClient {
  public client: AxiosInstance;
  constructor(client: AxiosInstance) {
    if (!client.defaults.baseURL) {
      throw new Error("client's baseURL MUST specified");
    }
    this.client = client;
  }
  healthStat = (req: schemas.HealthStatRequest, config: AxiosRequestConfig = {}): Response<schemas.HealthStat> => {
    return this.client.request({
      ...config,
      url: `/healthStats`,
      method: 'get',
    });
  };
  log = (req: schemas.logRequest, config: AxiosRequestConfig = {}): Response<schemas.logResp[]> => {
    return this.client.request({
      ...config,
      url: `/lokiLogs`,
      method: 'get',
      params: pick(req, ['type', 'property', 'start', 'end', 'limit', 'direction']),
    });
  };
  getSetting = (req: schemas.GetSettingRequest, config: AxiosRequestConfig = {}): Response<schemas.Setting> => {
    return this.client.request({
      ...config,
      url: `/setting`,
      method: 'get',
    });
  };
  updateSetting = (req: schemas.UpdateSettingRequest, config: AxiosRequestConfig = {}): Response<schemas.Setting> => {
    return this.client.request({
      ...config,
      url: `/setting`,
      method: 'patch',
      data: req.body,
    });
  };
}

type Response<T> = Promise<AxiosResponse<T>>;
type Dict = Record<string, any>;

function checkPathParams(op: string, req: Dict, ks: string[]) {
  ks.forEach((p) => {
    if (!req[p]) {
      throw new Error(`${p} is required for ${op}`);
    }
  });
}

function pick(o: Dict, ks: string[]) {
  return ks.reduce((r, p) => {
    r[p] = o[p];
    return r;
  }, {} as Dict);
}
