import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker/generatePicker';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { SHOW_DATA_YEARS } from 'src/config';

import { TimeType } from './card-range-picker';

const { RangePicker }: any = DatePicker;

const disabledDate = (current: dayjs.Dayjs) => {
  // Can not select days before today and today
  const startDate = dayjs().startOf('day').subtract(SHOW_DATA_YEARS, 'year');
  return current && (current > dayjs().startOf('day') || current < startDate);
};

const RangePickerContainer = styled.div`
  display: inline-block;
  margin-right: 16px;
  span {
    margin-left: 16px;
    color: #000;
    cursor: pointer;
    &:hover {
      color: #1890ff;
    }

    &.currentDate {
      color: #1890ff;
    }
  }
`;

/** 用于数据卡片页的时间切换 */
export const CardRangeTimePickerByHour = ({
  rangePickerValue,
  isActive,
  handleRangePickerChange,
  selectDate,
  noSpace = false,
  showTimeFormat = 'HH:mm',
}: {
  rangePickerValue: RangePickerProps<dayjs.Dayjs>['value'];
  isActive: (key: TimeType) => string;
  handleRangePickerChange: (dates: RangePickerProps<dayjs.Dayjs>['value'], dateStrings: [string, string]) => void;
  selectDate: (key: TimeType) => void;
  noSpace?: boolean;
  showTimeFormat: string;
}) => (
  <div>
    <RangePickerContainer>
      <span className={isActive('yesterday')} onClick={() => selectDate('yesterday')}>
        昨日
      </span>
      <span className={isActive('week')} onClick={() => selectDate('week')}>
        近7日
      </span>
      <span className={isActive('month')} onClick={() => selectDate('month')}>
        近30日
      </span>
      <span className={isActive('year')} onClick={() => selectDate('year')}>
        近1年
      </span>
    </RangePickerContainer>
    <RangePicker
      allowClear={false}
      value={rangePickerValue}
      onChange={handleRangePickerChange}
      style={{ width: 370 }}
      showTime={{ format: showTimeFormat }}
      disabledDate={disabledDate}
    />
    {!noSpace && <>&emsp;&emsp;</>}
  </div>
);
