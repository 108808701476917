import {
  AggregateVehiclesRequest,
  AggVehiclePropStatRequest,
  ApiError,
  ListVehiclesRequest,
} from '@36node-fcp/core-sdk';

import { useQuery } from 'src/lib/react-api';
import { fcp } from 'src/services';

/**
 * 获取车辆库列表
 * @param req 车辆库列表请求参数
 * @returns [state, listIllegalRecords]
 */
export const useVehicleList = (req: ListVehiclesRequest) => {
  const [state, listVehicleStoreRecords] = useQuery(fcp.listVehicles, req || {});
  return [state, listVehicleStoreRecords] as const;
};

/**
 * 获取违法记录统计
 */
export const useVehiclesRecordAggregation = (req: AggregateVehiclesRequest) => {
  const [state, aggregateVehicleRecords] = useQuery(fcp.aggregateVehicles, req || {});
  return [state, aggregateVehicleRecords] as const;
};

/**
 * Api Error
 */
export const VehicleStoreApiErrorMap: { [key: string]: { [key: string]: string } } = {
  [ApiError.Code.DUPLICATE]: {
    name: '车辆已存在',
  },
};

export const useAggVehiclePropStat = (req: AggVehiclePropStatRequest) => {
  const [state, aggVehiclePropStat] = useQuery(fcp.aggVehiclePropStat, req || {});
  return [state, aggVehiclePropStat] as const;
};
