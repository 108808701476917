import axios from 'axios';
import memoize from 'memoizee';

import { paramsSerializer } from 'src/lib/url';
import { injectToken } from 'src/sdk/helpers';
import { RobotAPIClient } from 'src/sdk/robot-sdk/client';

import { getToken } from './auth';

export const getRobotClient = memoize((baseURL: string): RobotAPIClient => {
  const instance = axios.create({ baseURL, paramsSerializer });
  instance.interceptors.request.use(injectToken(getToken));
  return new RobotAPIClient(instance);
});
