import { IllegalRecord, IllegalRecordState, ListIllegalRecordsRequest, Subject } from '@36node-fcp/core-sdk';
import { FileTextOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Empty,
  Flex,
  Form,
  Image,
  Input,
  message,
  Pagination,
  Popconfirm,
  Row,
  Select,
} from 'antd';
import dayjs from 'dayjs';
import { isArray, isEmpty, min } from 'lodash';
import styled from 'styled-components';

import { LabelItem } from 'src/components/line';
import { MAX_BATCH_REMOVE_RECORDS } from 'src/config';
import { toIllegalRecordExpireText, toIllegalRecordStateText, useIllegalRecordList } from 'src/features/illegal';
import { IllegalNameSelect } from 'src/features/illegal-type';
import { useSession } from 'src/features/session';
import { NamespaceCascaderSelect } from 'src/features/users';
import { useSearch } from 'src/hook/search';
import { ymdhms } from 'src/lib/lang/time';
import { checkPermission } from 'src/lib/privilege';
import { useApi } from 'src/lib/react-api';
import { useSlice } from 'src/lib/react-slice';
import { fcp } from 'src/services';

import { PersonIllegalEditor } from './illegal.editor';

const Container = styled(Card)`
  border: none !important;
  .ant-card-head {
    border: 0 !important;
    .ant-card-extra {
      padding: 8px 0;
    }
  }
  .ant-card-body {
    margin-bottom: 24px;
  }
`;

const ExtraButtonStyle = {
  color: '#999',
  padding: 0,
  lineHeight: 0,
  margin: '0 6px',
};

type SearchValues = {
  createAt?: any;
  expired?: any;
} & ListIllegalRecordsRequest;

type SearchFormProps = {
  onSearch: (values: SearchValues) => void;
  initialValues?: SearchValues;
};

const defaultQuery: ListIllegalRecordsRequest = {
  _limit: 10,
  _offset: 0,
  subject: [Subject.PERSON, Subject.NONMOTOR],
};

// 判断页面是否操作过查询
const isSearched = (search: SearchValues) => {
  // 切换页码不算查询
  // 默认查询条件不算查询
  // 这里因为 subject 不在页面上作为查询条件，所以简单排除掉即可
  const { _limit, _offset, subject, ...rest } = search;
  return !isEmpty(rest);
};

const SearchForm: React.FC<SearchFormProps> = ({ onSearch, initialValues }) => {
  return (
    <Container>
      <Form onFinish={onSearch} initialValues={initialValues} layout={'inline'}>
        <Form.Item name="place_like" label="违法地点：">
          <Input placeholder="违法地点" allowClear />
        </Form.Item>
        <Form.Item label="所属部门" name="ns_like">
          <NamespaceCascaderSelect style={{ width: 250 }} />
        </Form.Item>
        <Form.Item label="入库时间：" name="createAt">
          <DatePicker.RangePicker
            showTime
            showSecond={false}
            placeholder={['开始时间', '结束时间']}
            format="YYYY-MM-DD HH:mm"
          />
        </Form.Item>
        <Form.Item shouldUpdate>
          <Button type="primary" htmlType="submit">
            查询
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

const Title: React.FC<{ total: number }> = (props) => {
  return (
    <Flex align="center">
      <div style={{ fontSize: '16px', fontWeight: '500', color: 'color: rgba(0,0,0,0.85)' }}>违法列表</div>
      <div style={{ fontSize: '14px', color: 'rgba(0,0,0,0.25)', marginLeft: '12px' }}>
        <FileTextOutlined style={{ marginRight: '3px' }} />
        {/* <Link to="/confirm" style={{ color: 'rgba(0,0,0,0.25)' }}>
          待确认违法共 {props.total} 条
        </Link> */}
        <span style={{ color: 'rgba(0,0,0,0.25)' }}>待确认违法共 {props.total} 条</span>
      </div>
    </Flex>
  );
};

type State = {
  editorVisible: boolean;
};

const initState: State = {
  editorVisible: false,
};

const reducers = {
  openEditor(state: State) {
    state.editorVisible = true;
  },
  closeEditor(state: State) {
    state.editorVisible = false;
  },
};

const toQuery = (values: SearchValues): ListIllegalRecordsRequest => {
  const { createAt, expired, ...rest } = values;
  if (createAt && isArray(createAt) && createAt[0] && createAt[1]) {
    rest.createAt_gt = dayjs(values.createAt[0].toISOString()).startOf('minute').toISOString();
    rest.createAt_lt = dayjs(values.createAt[1].toISOString()).endOf('minute').toISOString();
  } else if (createAt === null) {
    rest.createAt_gt = undefined;
    rest.createAt_lt = undefined;
  }

  if (expired) {
    if (expired === 'EFFECTIVE') {
      rest.expiredAt_gt = dayjs().startOf('minute').toISOString();
      rest.expiredAt_lt = undefined;
    } else if (expired === 'EXPIRED') {
      rest.expiredAt_lt = dayjs().endOf('minute').toISOString();
      rest.expiredAt_gt = undefined;
    }
  }

  return { ...defaultQuery, ...rest };
};

/**
 * 人员违法管理页面
 */
const PersonIllegalPage: React.FC = () => {
  const session = useSession();
  const [search, setSearch] = useSearch<SearchValues>();
  const [{ result, loading, request = {}, total }, listIllegalRecords] = useIllegalRecordList(toQuery(search));
  const { _limit: limit = 10, _offset: offset = 0 } = request;
  const [{ editorVisible }, dispatch] = useSlice(reducers, initState);

  const [deleteState, deleteIllegalRecord] = useApi(fcp.deleteIllegalRecord, {
    onSuccess: () => {
      message.success('删除成功');
      listIllegalRecords(request);
    },
    onFailure: (err) => {
      message.error(`删除失败: ${err.message}`);
    },
  });

  const [batchDeleteState, batchDeleteIllegalRecord] = useApi(fcp.batchDeleteIllegalRecords, {
    onSuccess: () => {
      message.success(`批量删除成功，本次共删除${min([total, MAX_BATCH_REMOVE_RECORDS])}条数据`);
      listIllegalRecords(request);
    },
    onFailure: (err) => {
      message.error(`批量删除失败: ${err.message}`);
    },
  });

  const [closeState, closeIllegal] = useApi(fcp.closeIllegal, {
    onSuccess: () => {
      message.success('更新成功');
      listIllegalRecords(request);
    },
    onFailure: (err) => {
      message.error(`更新失败: ${err.message}`);
    },
  });

  // 搜索、分页、排序触发
  const handleSearch = (values: SearchValues) => {
    setSearch({
      ...search, // 上一次留存的查询条件
      _offset: 0, // 重置页码
      ...values, // 本次输入的查询条件，取消的条件用 undefined 覆盖上一次的查询条件
    });
  };

  const handlePageChange = (page, pageSize) => {
    handleSearch({
      ...search,
      _limit: pageSize,
      _offset: (page - 1) * pageSize,
    });
  };

  const handleRefresh = () => listIllegalRecords(request);
  const handleBatchDelete = () => batchDeleteIllegalRecord(request as any);

  const PersonIllegalItem = ({ illegalRecord }: { illegalRecord: IllegalRecord }) => (
    <Col xxl={4} xl={6} lg={8}>
      <Image src={illegalRecord.images[0]} alt="image" />
      <LabelItem title="违法名称：" content={illegalRecord.name} />
      <LabelItem title="违法地点：" content={illegalRecord.place} />
      <LabelItem title="入库时间：" content={illegalRecord.createAt} formatter={ymdhms} />
      <LabelItem title="违法时间：" content={illegalRecord.capAt} formatter={ymdhms} />
      <LabelItem title="来源：" content={illegalRecord.source} />
      <LabelItem title="状态：" content={illegalRecord.state} formatter={toIllegalRecordStateText} />
      <LabelItem
        title="是否过期："
        content={illegalRecord.expiredAt && dayjs(illegalRecord.expiredAt).isAfter(dayjs()) ? 'EFFECTIVE' : 'EXPIRED'}
        formatter={toIllegalRecordExpireText}
      />
      <div>
        {checkPermission(session, ['person:peopleIllegalAd']) && illegalRecord.state !== IllegalRecordState.CLOSED && (
          <Popconfirm
            title="你确定要关闭当前违法吗？"
            onConfirm={() => closeIllegal({ illegalRecordId: illegalRecord.id })}
          >
            <Button type="link" loading={closeState.loading} style={{ marginLeft: -16 }}>
              关闭
            </Button>
          </Popconfirm>
        )}

        {checkPermission(session, ['person:peopleIllegalAd']) && (
          <Popconfirm
            title="你确定要删除当前违法吗？"
            onConfirm={() => deleteIllegalRecord({ illegalRecordId: illegalRecord.id })}
          >
            <Button type="link" danger style={{ padding: 0 }} loading={deleteState.loading}>
              删除
            </Button>
          </Popconfirm>
        )}
      </div>
    </Col>
  );

  const handleStateFilter = (value) => {
    if (value) {
      setSearch({
        ...search,
        _offset: 0,
        state: value,
      });
    } else {
      const { state, ...rest } = search;
      setSearch({
        ...rest,
        _offset: 0,
      });
    }
  };

  const handleExpireFilter = (value) => {
    setSearch({
      ...search,
      _offset: 0,
      expired: value,
    });
  };

  const handleNameFilter = (value) => {
    if (value) {
      setSearch({
        ...search,
        _offset: 0,
        name: value,
      });
    } else {
      const { name, ...rest } = search;
      setSearch({
        ...rest,
        _offset: 0,
      });
    }
  };

  return (
    <>
      <SearchForm onSearch={handleSearch} initialValues={search} />
      <Card
        title={<Title total={total} />}
        loading={loading}
        extra={
          <>
            {checkPermission(session, ['person:peopleIllegalAd']) && (
              <Popconfirm
                title={
                  <>
                    是否将查询到的内容全部删除？
                    <br />
                    将删除{min([total, MAX_BATCH_REMOVE_RECORDS])}条数据
                  </>
                }
                onConfirm={handleBatchDelete}
              >
                <Button
                  disabled={!isSearched(search) || !total || loading}
                  loading={batchDeleteState.loading}
                  type="primary"
                >
                  按查询结果删除
                </Button>
              </Popconfirm>
            )}
            <Divider type="vertical" />
            <IllegalNameSelect
              placeholder="选择违法名称"
              subject={[Subject.PERSON, Subject.NONMOTOR]}
              allowClear
              onChange={handleNameFilter}
              style={{ minWidth: 200 }}
              defaultValue={search?.name}
            />
            <Select
              placeholder="是否过期"
              allowClear
              style={{ minWidth: 200, marginLeft: '24px' }}
              onChange={handleExpireFilter}
              defaultValue={search?.expired}
            >
              <Select.Option key="EFFECTIVE" value="EFFECTIVE">
                未过期
              </Select.Option>
              <Select.Option key="EXPIRED" value="EXPIRED">
                已过期
              </Select.Option>
            </Select>
            <Select
              placeholder="请选择状态"
              allowClear
              style={{ minWidth: 200, margin: '0 24px' }}
              onChange={handleStateFilter}
              defaultValue={search?.state}
            >
              <Select.Option key="CLOSED" value="CLOSED">
                已关闭
              </Select.Option>
              <Select.Option key="OPEN" value="OPEN">
                未关闭
              </Select.Option>
            </Select>
            {checkPermission(session, ['person:peopleIllegalAd']) && (
              <Button type="link" size="large" style={ExtraButtonStyle} onClick={() => dispatch.openEditor()}>
                <PlusOutlined />
              </Button>
            )}
            <Button type="link" size="large" style={ExtraButtonStyle} onClick={handleRefresh}>
              <RedoOutlined />
            </Button>
          </>
        }
        actions={
          result?.length > 0 && [
            <Pagination current={offset / limit + 1} total={total} pageSize={limit} onChange={handlePageChange} />,
          ]
        }
      >
        {result?.length > 0 ? (
          <Row gutter={[48, 48]}>{result?.map((item) => <PersonIllegalItem illegalRecord={item} key={item.id} />)}</Row>
        ) : (
          <Empty />
        )}
      </Card>
      {editorVisible && <PersonIllegalEditor onClose={dispatch.closeEditor} onFinish={handleRefresh} />}
    </>
  );
};

export default PersonIllegalPage;
