import { Subject } from '@36node-fcp/core-sdk';
import { Button, Card, Divider, Flex, Form, InputNumber } from 'antd';
import dayjs from 'dayjs';
import { createContext, useContext, useMemo } from 'react';
import { styled } from 'styled-components';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { CardRangePicker, getTimeDistance, RangePickerValue, TimeType } from 'src/components/card-range-picker';
import { ExportModal } from 'src/components/xlsx-export-modal';
import { IllegalTypeSelect } from 'src/features/illegal-type';
import {
  IllegalVehicleReportItem,
  IllegalVehicleReportRequest,
  useIllegalVehicleReport,
} from 'src/features/illegal-vehicle-report';
import { CaseReducerActions, useSlice } from 'src/lib/react-slice';

type PageState = {
  search: IllegalVehicleReportRequest & {
    rangePickerValue: RangePickerValue;
  };
  exportVisible: boolean;
};

const initState: PageState = {
  search: {
    rangePickerValue: getTimeDistance('yesterday'),
    createAt_gt: getTimeDistance('yesterday')[0].toISOString(), //时间根据选择改变
    createAt_lt: getTimeDistance('yesterday')[1].toISOString(), //时间根据选择改变
    count_gt: 5,
  },
  exportVisible: false,
};

const reducers = {
  setDateRange: (state: PageState, dateRange: RangePickerValue) => {
    state.search.rangePickerValue = dateRange;
    state.search.createAt_gt = dateRange[0].startOf('day').toISOString();
    state.search.createAt_lt = dateRange[1].add(1, 'day').startOf('day').toISOString();
  },
  setSearch: (state: PageState, search: Partial<IllegalVehicleReportRequest>) => {
    state.search = {
      ...state.search,
      ...search,
    };
  },
  openExport: (state: PageState) => {
    state.exportVisible = true;
  },
  closeExport: (state: PageState) => {
    state.exportVisible = false;
  },
};

type SearchValues = IllegalVehicleReportRequest;

const StateContext = createContext<PageState>(null);
const DispatchContext = createContext<CaseReducerActions<typeof reducers>>(null);

const Provider = ({ children }) => {
  const [state, dispatch] = useSlice(reducers, initState);
  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
};

const usePageState = () => {
  const dispatch = useContext(DispatchContext);
  const search = useContext(StateContext);
  return [search, dispatch] as const;
};

const SearchForm: React.FC = () => {
  const [, dispatch] = usePageState();

  const handleFinish = (values: SearchValues) => {
    dispatch.setSearch({
      ...values,
    });
  };

  return (
    <Form onFinish={handleFinish} initialValues={initState.search} layout="inline">
      <Form.Item label="违法类型" name="code">
        <IllegalTypeSelect subject={[Subject.VEHICLE]} style={{ width: '200px' }} placeholder="请选择" allowClear />
      </Form.Item>

      <Form.Item label="违法数 > " name="count_gt">
        <InputNumber />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          查询
        </Button>
      </Form.Item>
    </Form>
  );
};

const NarrowDown: React.FC = () => {
  const [{ search }, dispatch] = usePageState();

  const { rangePickerValue } = search;

  const isActive = (type: TimeType) => {
    if (!rangePickerValue) {
      return '';
    }
    const value = getTimeDistance(type);
    if (!value) {
      return '';
    }
    if (!rangePickerValue[0] || !rangePickerValue[1]) {
      return '';
    }
    if (
      rangePickerValue[0].isSame(value[0] as dayjs.Dayjs, 'day') &&
      rangePickerValue[1].isSame(value[1] as dayjs.Dayjs, 'day')
    ) {
      return 'currentDate';
    }
    return '';
  };

  const selectDate = (type: TimeType) => {
    const dates = getTimeDistance(type);
    dispatch.setDateRange(dates);
  };

  return (
    <Flex gap="24">
      <CardRangePicker
        rangePickerValue={search.rangePickerValue}
        isActive={isActive}
        handleRangePickerChange={(dates) => {
          dispatch.setDateRange(dates);
        }}
        noSpace
        selectDate={selectDate}
      />
    </Flex>
  );
};

const IllegalVehicleTable: React.FC = () => {
  const [{ search, exportVisible }, dispatch] = usePageState();
  const [{ result, loading, total }] = useIllegalVehicleReport(search);

  const columns = useMemo<AdColumnsType<IllegalVehicleReportItem>>(() => {
    return [
      {
        title: '车牌',
        dataIndex: 'plate',
      },
      {
        title: '号牌种类',
        dataIndex: 'plateType',
      },
      {
        title: '使用性质',
        dataIndex: 'vehicleProperty',
      },
      {
        title: '违法数',
        dataIndex: 'count',
      },
    ];
  }, []);

  return (
    <>
      <AdTable
        bordered
        title={<div>高频违法车({total})</div>}
        columns={columns}
        rowKey="plate"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={result}
        showColumnsFilter={false}
        pagination={{ pageSize: 10 }}
        extraTools={<NarrowDown />}
        onExport={total > 0 && dispatch.openExport}
      />

      {exportVisible && (
        <ExportModal
          dataSource={result}
          columns={columns}
          filename="高频违法车.xlsx"
          onClose={dispatch.closeExport}
          title="高频违法车"
          total={total}
        />
      )}
    </>
  );
};

/**
 * 高频违法车页面
 */
const IllegalVehiclePage: React.FC = () => {
  return (
    <>
      <Provider>
        <Container
          bodyStyle={{
            padding: '12px 24px',
            marginBottom: '20px',
          }}
        >
          <SearchForm />
        </Container>
        <Divider />
        <IllegalVehicleTable />
      </Provider>
    </>
  );
};

const Container = styled(Card)`
  border: none !important;
  min-width: 1200px;
  .ant-card-head {
    border: 0 !important;
    .ant-card-extra {
      padding: 8px 0;
    }
  }
  .ant-typography {
    color: rgba(0, 0, 0, 0.65);
  }
`;

export default IllegalVehiclePage;
