import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker/generatePicker';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { SHOW_DATA_YEARS } from 'src/config';

export type TimeType = 'yesterday' | 'week' | 'month' | 'year';
export type RangePickerValue = RangePickerProps<dayjs.Dayjs>['value'];

const { RangePicker }: any = DatePicker;

const disabledDate = (current: dayjs.Dayjs) => {
  // Can not select days before today and today
  const startDate = dayjs().startOf('day').subtract(SHOW_DATA_YEARS, 'year');
  return current && (current > dayjs().startOf('day') || current < startDate);
};

export function getTimeDistance(type: TimeType): RangePickerValue {
  const startOfToday = dayjs().startOf('day');

  if (type === 'yesterday') {
    return [dayjs().subtract(1, 'day').startOf('day'), startOfToday];
  }

  if (type === 'week') {
    const sevenDaysAgo = startOfToday.subtract(7, 'day');
    return [sevenDaysAgo, startOfToday];
  }

  if (type === 'month') {
    const oneMonthAgo = startOfToday.subtract(1, 'month');
    return [oneMonthAgo, startOfToday];
  }

  const oneYearAgo = startOfToday.subtract(1, 'year');
  return [oneYearAgo, startOfToday];
}

const RangePickerContainer = styled.div`
  display: inline-block;
  margin-right: 16px;
  span {
    margin-left: 16px;
    color: #000;
    cursor: pointer;
    &:hover {
      color: #1890ff;
    }

    &.currentDate {
      color: #1890ff;
    }
  }
`;

/** 用于数据卡片页的时间切换 */
export const CardRangePicker = ({
  rangePickerValue,
  isActive,
  handleRangePickerChange,
  selectDate,
  showTime,
  noSpace = false,
}: {
  rangePickerValue: RangePickerProps<dayjs.Dayjs>['value'];
  isActive: (key: TimeType) => string;
  handleRangePickerChange: (dates: RangePickerProps<dayjs.Dayjs>['value'], dateStrings: [string, string]) => void;
  selectDate: (key: TimeType) => void;
  showTime?: boolean;
  noSpace?: boolean;
}) => (
  <div>
    {!showTime && (
      <RangePickerContainer>
        <span className={isActive('yesterday')} onClick={() => selectDate('yesterday')}>
          昨日
        </span>
        <span className={isActive('week')} onClick={() => selectDate('week')}>
          近7日
        </span>
        <span className={isActive('month')} onClick={() => selectDate('month')}>
          近30日
        </span>
        <span className={isActive('year')} onClick={() => selectDate('year')}>
          近1年
        </span>
      </RangePickerContainer>
    )}
    <RangePicker
      allowClear={false}
      value={rangePickerValue}
      onChange={handleRangePickerChange}
      style={{ width: showTime ? 370 : 256 }}
      disabledDate={disabledDate}
      showTime={showTime}
    />
    {!noSpace && <>&emsp;&emsp;</>}
  </div>
);
