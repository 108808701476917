import { Namespace } from '@36node-fcp/auth-sdk';
import { ListWarningsRequest, Subject, Warning } from '@36node-fcp/core-sdk';
import { Button, Card, DatePicker, Form, Input, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { intersection, isArray } from 'lodash';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { ExportModal } from 'src/components/xlsx-export-modal';
import { ROOT_NS } from 'src/config';
import { useGantryList } from 'src/features/gantry';
import {
  IllegalLevelBadge,
  IllegalLevelFilter,
  toIllegalLevelText,
  useIllegalTypeList,
} from 'src/features/illegal-type';
import { useLawEnforcePointList } from 'src/features/law-enforce-point';
import { useNamespaceList } from 'src/features/users';
import {
  toWarningStateText,
  useWarningList,
  WarningBlacklistFilter,
  WarningStateBadge,
  WarningStateFilter,
} from 'src/features/warning';
import { useSearch } from 'src/hook/search';
import { ymdhms } from 'src/lib/lang/time';
import { useSlice } from 'src/lib/react-slice';
import { fcp } from 'src/services';

const Container = styled(Card)`
  border: none !important;
  .ant-card-head {
    border: 0 !important;
    .ant-card-extra {
      padding: 8px 0;
    }
  }
  .ant-card-body {
    margin-bottom: 24px;
  }
`;

type SearchValues = {
  capAt?: Dayjs[];
  gantry?: string[];
  lawEnforcePoint?: string[]; // 这里实际存的是 gantry id
  blacklist?: boolean;
} & ListWarningsRequest;

type SearchFormProps = {
  onSearch: (values: SearchValues) => void;
  initialValues?: SearchValues;
};

const defaultQuery: ListWarningsRequest = {
  _limit: 10,
  _offset: 0,
  subject: [Subject.VEHICLE],
};

const SearchForm: React.FC<SearchFormProps> = ({ onSearch, initialValues }) => {
  return (
    <Container>
      <Form onFinish={onSearch} initialValues={initialValues} layout={'inline'}>
        <Form.Item name="plate" label="车牌：">
          <Input placeholder="车牌查询" allowClear />
        </Form.Item>
        <Form.Item name="plateType" label="号牌种类：">
          <Input placeholder="号牌种类查询" allowClear />
        </Form.Item>
        <Form.Item label="过车时间：" name="capAt">
          <DatePicker.RangePicker
            showTime
            showSecond={false}
            placeholder={['开始时间', '结束时间']}
            format="YYYY-MM-DD HH:mm"
          />
        </Form.Item>
        <Form.Item shouldUpdate>
          <Button type="primary" htmlType="submit">
            查询
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

type State = {
  exportVisible: boolean;
};

const initState: State = {
  exportVisible: false,
};

const reducers = {
  openExport(state: State) {
    state.exportVisible = true;
  },
  closeExport(state: State) {
    state.exportVisible = false;
  },
};

/**
 * 车辆预警管理页面
 */
const VehicleWarningPage: React.FC = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useSearch<SearchValues>();

  const [{ result: gantries = [] }] = useGantryList({ _limit: 1000 });
  const [{ result: allLawEnforcePoints = [] }] = useLawEnforcePointList({ _limit: 1000 });
  const [{ result: illegalTypes = [] }] = useIllegalTypeList({ _limit: 1000, subject: [Subject.VEHICLE] });
  const [{ result: namespaces = [] }] = useNamespaceList({ ns_start: ROOT_NS, _limit: 1000 });

  const toQuery = (values: SearchValues) => {
    const { blacklist: blacklist_exists, lawEnforcePoint = [], gantry = [], ...rest } = values;
    if (rest.capAt && isArray(rest.capAt) && rest.capAt[0] && rest.capAt[1]) {
      rest.capAt_gte = dayjs(rest.capAt[0].toISOString()).startOf('minute').toISOString();
      rest.capAt_lte = dayjs(rest.capAt[1].toISOString()).endOf('minute').toISOString();
    }
    delete rest.capAt;
    const l2g = []; //执法点对应的gantry
    if (lawEnforcePoint.length > 0) {
      let lawEnforcePointArray;
      if (!isArray(lawEnforcePoint)) {
        lawEnforcePointArray = [lawEnforcePoint];
      } else {
        lawEnforcePointArray = lawEnforcePoint;
      }
      for (let i = 0; i < lawEnforcePointArray.length; i++) {
        const selectGantries = gantries
          ?.filter((gantry) => gantry.lawEnforcePoint?.id === lawEnforcePointArray[i])
          .map((item) => item.id);
        if (selectGantries && selectGantries.length > 0) {
          l2g.push(...selectGantries);
        }
      }
    }
    let gantryIds = [];
    // 同时存在取交集
    if (gantry.length > 0 && l2g.length > 0) {
      if (isArray(gantry)) {
        gantryIds = intersection(l2g, gantry);
      } else {
        gantryIds = intersection(l2g, [gantry]);
      }
      if (gantryIds.length === 0) {
        gantryIds = ['-1']; // 同时存在但是交集为空，返回空
      }
    } else {
      gantryIds = gantry && gantry.length > 0 ? gantry : l2g;
    }
    return { ...defaultQuery, ...rest, gantry: [].concat(gantryIds), blacklist_exists };
  };

  const [{ result, loading, request = {}, total }, listVehicleWarningRecords] = useWarningList(toQuery(search));

  const { _limit: limit = 10, _offset: offset = 0 } = request;
  const [{ exportVisible }, dispatch] = useSlice(reducers, initState);

  // 搜索、分页、排序触发
  const handleSearch = (values: SearchValues) => {
    setSearch({
      ...search, // 上一次留存的查询条件
      _offset: 0, // 重置页码
      ...values, // 本次输入的查询条件，取消的条件用 undefined 覆盖上一次的查询条件
    });
  };

  const handleRefresh = () => listVehicleWarningRecords(request);

  const columns = useMemo<AdColumnsType<Warning>>(
    () => [
      {
        title: '车牌',
        dataIndex: 'plate',
      },
      {
        title: '号牌种类',
        dataIndex: 'plateType',
      },
      {
        title: '违法名称',
        dataIndex: 'name',
        filters: illegalTypes.map((item) => ({ text: item.name, value: item.name })),
        filterSearch: true,
        defaultFilteredValue: search.name && [].concat(search.name),
      },
      {
        title: '预警级别',
        dataIndex: 'level',
        render: (val) => <IllegalLevelBadge level={val} />,
        compute: toIllegalLevelText,
        filters: IllegalLevelFilter,
        filterMultiple: false,
        defaultFilteredValue: search.level && [String(search.level)],
      },
      {
        title: '黑名单',
        dataIndex: 'blacklist',
        compute: (blacklist) => blacklist?.content,
        filters: WarningBlacklistFilter,
        filterMultiple: false,
        defaultFilteredValue: search.blacklist !== undefined && [String(search.blacklist)],
      },
      {
        title: '过车时间',
        dataIndex: 'capAt',
        compute: ymdhms,
      },
      {
        title: '创建时间',
        dataIndex: 'createAt',
        compute: ymdhms,
        defaultHidden: true,
      },
      {
        title: '预警卡口',
        key: 'gantry',
        dataIndex: ['gantry', 'name'],
        filters: gantries?.map((item) => ({ text: item.name, value: item.id })),
        defaultFilteredValue: search.gantry && [].concat(search.gantry),
        filterSearch: true,
      },
      {
        title: '所属部门',
        key: 'ns_like',
        dataIndex: ['gantry', 'ns'],
        filters: namespaces?.map((item: Namespace) => ({ text: item.name, value: item.ns })).reverse(),
        filterMultiple: false,
        compute: (val) => namespaces?.find((item) => item.ns === val)?.name || '--',
        import: false,
        defaultFilteredValue: search?.ns_like && [search.ns_like],
      },
      {
        title: '所属执法点',
        key: 'lawEnforcePoint',
        dataIndex: ['gantry', 'lawEnforcePoint', 'name'],
        filters: allLawEnforcePoints?.map((item) => ({ text: item.name, value: item.id })),
        filterSearch: true,
        defaultFilteredValue: search.lawEnforcePoint && [].concat(search.lawEnforcePoint),
      },
      {
        title: '状态',
        dataIndex: 'state',
        render: (val) => <WarningStateBadge state={val} />,
        compute: toWarningStateText,
        defaultFilteredValue: search.state && [String(search.state)],
        filterMultiple: false,
        filters: WarningStateFilter,
      },

      {
        title: '操作',
        render: (node, record) => (
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => {
              navigate(`${record.id}`);
            }}
          >
            详情
          </Button>
        ),
      },
    ],
    [dispatch, namespaces, allLawEnforcePoints, illegalTypes, gantries]
  );

  if (!namespaces || !allLawEnforcePoints || !gantries || !illegalTypes) {
    return <Spin />;
  }

  return (
    <>
      <SearchForm onSearch={handleSearch} initialValues={search} />
      <AdTable
        columns={columns}
        title="车辆预警列表"
        rowKey="id"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={result}
        onChange={handleSearch}
        onRefresh={handleRefresh}
        onExport={dispatch.openExport}
        pagination={{
          total,
          current: offset / limit + 1,
          pageSize: limit,
        }}
      />
      {exportVisible && (
        <ExportModal
          api={fcp.listWarnings}
          args={request}
          columns={columns}
          filename="车辆预警列表.xlsx"
          onClose={dispatch.closeExport}
          title="车辆预警列表"
          total={total}
        />
      )}
    </>
  );
};

export default VehicleWarningPage;
