import { DownloadOutlined, InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, message, Space, Tooltip, Upload } from 'antd';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import styled from 'styled-components';

import { useRules } from 'src/features/rule';
import { useApi } from 'src/lib/react-api';
import { useSlice } from 'src/lib/react-slice';
import { fcp } from 'src/services';

import { CustomIllegalTypePage } from './custom-illegal-type.page';
import { RuleEntityEditor } from './rule.editor';

type State = {
  editorVisible: boolean;
  editIndex?: number;
};

const initState: State = {
  editorVisible: false,
  editIndex: -1,
};

const reducers = {
  openEditor(state: State, editIndex?: number) {
    state.editorVisible = true;
    state.editIndex = editIndex;
  },
  closeEditor(state: State) {
    state.editorVisible = false;
    state.editIndex = -1;
  },
};

const ExtraButtonStyle = {
  color: '#999',
  padding: 0,
  lineHeight: 0,
};

const RulePage: React.FC = () => {
  const [{ editorVisible, editIndex }, dispatch] = useSlice(reducers, initState);

  const [{ result: rules }, listRules] = useRules();
  const [, update] = useApi(fcp.updateRule);

  const [importing, setImporting] = useState(false);
  const [exporting, setExporting] = useState(false);

  const handleExport = () => {
    setExporting(true);
    listRules({})
      .then((res) => {
        const jsonString = JSON.stringify(res, null, 2);
        const blob = new Blob([jsonString], { type: 'application/json;charset=utf-8' });
        saveAs(blob, '规则管理.json');
        message.success('导出成功');
      })
      .catch((err) => {
        message.error(err.message || '获取配置失败');
      })
      .finally(() => {
        setExporting(false);
      });
  };

  const handleFileUpload = (file: File) => {
    const isJson = file.type === 'application/json';
    if (!isJson) {
      message.error('请上传JSON文件');
      return Upload.LIST_IGNORE;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      setImporting(true);
      try {
        const result = e.target?.result;
        if (result) {
          const jsonData = JSON.parse(result as string);

          if (!jsonData.entities) {
            throw new Error('配置文件格式不正确');
          }

          await update({
            body: jsonData,
          })
            .then(() => {
              message.success('导入成功');
              listRules({});
            })
            .catch(() => {
              message.error('导入失败');
            });
        } else {
          throw new Error('配置文件读取失败');
        }
      } catch (error) {
        message.error(error.message || '配置文件格式不正确');
      } finally {
        setImporting(false);
      }
    };
    reader.readAsText(file);

    return false;
  };

  return (
    <Container>
      <Top>
        <div className="title">
          车辆自定义违法转换
          <Tooltip placement="right" title="规则顺序代表执行优先级，可用拖拽方式改变优先级">
            <InfoCircleOutlined style={{ marginLeft: 6 }} />
          </Tooltip>
        </div>
        <Space>
          <Button type="primary" onClick={() => dispatch.openEditor()} style={{ marginRight: '12px' }}>
            新建规则
          </Button>

          <Tooltip title="导入配置">
            <Upload beforeUpload={handleFileUpload} showUploadList={false} accept=".json">
              <Button type="link" size="large" style={ExtraButtonStyle} loading={importing}>
                <UploadOutlined />
              </Button>
            </Upload>
          </Tooltip>

          <Tooltip title="导出配置">
            <Button type="link" size="large" style={ExtraButtonStyle} onClick={handleExport} loading={exporting}>
              <DownloadOutlined />
            </Button>
          </Tooltip>
        </Space>
      </Top>
      <CustomIllegalTypePage
        records={rules?.entities}
        onEdit={(index) => dispatch.openEditor(index)}
        onRefresh={() => listRules({})}
      />
      {editorVisible && (
        <RuleEntityEditor
          onClose={dispatch.closeEditor}
          onFinish={() => listRules({})}
          records={rules?.entities}
          index={editIndex}
        />
      )}
    </Container>
  );
};
export default RulePage;

const Container = styled.div`
  margin: 16px 24px;
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .title {
    font-size: 1rem;
    font-weight: 600;
    color: rgb(0, 0, 0, 0.88);
    line-height: 1.5;
  }
`;
